import React from "react";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import moment from "moment";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Clock,
  Edit
} from "react-feather";
import { isEmpty } from "lodash";

moment.locale('ru'); 

const OrderCard = ({ item, handler, index, handleTimerPlus, cutOverdueOrder, multiClose, updColor, colors, debt }) => {
  const [copyStatus, setCopyStatus] = React.useState({});

  const handleOrder = async (id, value) => {
    multiClose(id, value);
  }

  return (
    <>
    <div className="p-3 ">
      <div className={`card ${!isEmpty(colors) && colors.includes(item.id) ? "bg-activ" : ''}`}>
        <div className="card-body">
          <div className="col text-white">
              {/* шапка блока */}
              <div className="d-flex justify-content-between mt-2">
                {/* left side */}
                <div className="">
                  <div className="d-flex">
                  <div className="form-check form-check-flat form-check-primary">
                    <label className="form-check-label">
                      <input 
                        type="checkbox" 
                        className="form-check-input" 
                        onChange={(e) => handleOrder(item.id, e.target.checked)}
                      />
                      <i className="input-frame"></i>
                    </label>
                  </div>
                  <h6 className="alert-heading mt-2">Заявка №{item.id}</h6>
                  </div>
                  <p className="mt-2">
                    <strong>Статус:<span
                      style={{ fontSize: 14, marginLeft: '5px' }}
                      className={`${
                        item.status === "COMPLETED"
                        ? "text-success"
                        : item.status === "PENDING"
                        ? "text-primary"
                        : item.status === "ERROR"
                        ? "text-danger"
                        : "text-warning"
                      }`}
                      >
                      {item.status === "COMPLETED"
                        ? " выполненная"
                        : item.status === "PENDING"
                        ? " в работе"
                        : item.status === "ERROR"
                        ? " ошибочная"
                        : " новая"}
                    </span>
                    </strong>
                  </p>
                  <button 
                    onClick={() => handleTimerPlus(item.id)}
                    className="btn btn-four btn-small-xxs mt-2"
                  >
                    Продлить таймер<Clock className="link-icon ms-2 pb-1" style={{width: '15px'}}/>
                  </button>
                </div>
                
                {/* right side */}
                <div className="countdownTimer">
                  {item.assignedAt && (
                    <CountdownCircleTimer
                      isPlaying
                      duration={item.timerTime * 60}
                      initialRemainingTime={moment(item.assignedAt).add(item.timerTime, 'minutes').diff(moment(), 'seconds')}
                      colors={['#10b759', '#FFBF00', '#f41b51', '#FF0000']}
                      colorsTime={[900, 720, 240, 0]}
                      size={80}
                      strokeWidth={5}
                      updateInterval={1}
                      isSmoothColorTransition={false}
                      onComplete={() => cutOverdueOrder(item.id)}
                    >
                      {({ remainingTime }) => {
                          const minutes = Math.floor(remainingTime / 60)
                          const seconds = remainingTime % 60

                          return <div className="text-center"><strong>{minutes}:{seconds}</strong><br /><span style={{fontSize: '12px'}}>минут</span></div>
                        }
                      }
                    </CountdownCircleTimer>
                  )}
                </div>
              </div>
              <hr style={{margin: '20px 0'}}/>
              <div className="position-relative">
                Карта:
                {copyStatus.copied && index === copyStatus.index && 
                  <div className="popover" style={{top: '30px'}} >
                    <div className="arrow"></div>
                    <p>Скопировано</p>
                  </div>  
                }
                <span className={`mr-1 ${copyStatus.copied && index === copyStatus.index ? 'text-success' : ''} ${debt ? 'text-danger fw-bold' : ''}`}> {item.card}</span>
                <CopyToClipboard text={`Карта: ${item.card} | Банк: ${item.bank} | Сумма: ${item.amount} руб. | APEX`}
                  onCopy={() => {
                    setCopyStatus({ copied: true, index: index })
                    updColor(item.id)
                    setTimeout(() => {
                      setCopyStatus({ copied: false })
                    }, 1000);
                  }}
                >
                  <i className="fa-solid fa-copy copy-card"></i>
                </CopyToClipboard>
              </div>
              <p className={`mt-2 ${copyStatus.copied && index === copyStatus.index ? 'text-success' : ''}`}>
                Банк: <strong>{item.bank}</strong>
              </p>
              <p className={`mt-2 ${copyStatus.copied && index === copyStatus.index ? 'text-success' : ''}`}>
                Сумма: <strong>{item.amount} руб.</strong>
              </p>
              <hr style={{margin: '20px 0'}}/>
              {item.comment &&
                <p className="mt-2">
                  Коммент: <strong>{item.comment}</strong>
                </p>
              }
              {item.comment2 &&
                <p className="mt-2">
                  Коммент: <strong>{item.comment2}</strong>
                </p>
              }
              <p className="mt-2">
                Создана: {moment(item.createdAt).format("D.MM.YYYY, H:mm:ss")}
              </p>
              {/* <hr style={{margin: '20px 0'}} /> */}
              <div className="">
                <button
                  className="btn btn-green-two btn-small-s mx-auto mt-3"
                  onClick={() => handler(item.id)}
                >
                  Изменить статус<Edit className="link-icon ms-2 pb-1" style={{width: '18px'}}/>
                </button>
              </div>
            </div> 
          </div> 
        </div>
      </div>
    </>
  );
};

export default OrderCard;
