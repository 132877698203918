import React, { useContext, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty, isNaN } from "lodash";
import DataTable, {createTheme} from "react-data-table-component";

import Loader from "../../components/Loader";
import { sendNotification } from "../../helpers/notification";
import AdminBar from "../../components/AdminBar";
import ModalEditBallance from "../../components/modals/ModalEditBallance";
import { UserContext } from "../../context/users-context";
import { Key, Pocket, User, Users, AlignJustify } from "react-feather";

const UsersAdmin = () => {

  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [showEditUserModal, setShowEditUserModal] = React.useState(false);
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    setLoading(false);
  }, [loading]); 

  useEffect(() => {
    UserCunsumer.getUsers();
    return () => UserCunsumer.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const userFilter = (role) => {
    let users = UserCunsumer.users.filter((user) => user.role === role);
    return (users);
  }

  useEffect(() => {
    if (UserCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", UserCunsumer.redirectMessage);
      setRedirectPath(UserCunsumer.redirectPath);
      UserCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserCunsumer.redirect]);

  const togglePopUp = useCallback(
    (trigger) => {
      setShowEditUserModal(false);
      UserCunsumer.updateState('balanceAmount', '');
      UserCunsumer.updateState('comment', '');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showEditUserModal] 
  );

  const handler = (id) => {
    UserCunsumer.setUserToEdit(id);
    setShowEditUserModal(true);
  };

  const updateData = () => {
    UserCunsumer.clear();
    setTimeout(() => {
      UserCunsumer.getUsers();
    }, 200);
  };

  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "270px",
      cell: (row) => {
        return (
          <span style={{color: '#007bff'}}>{row.email}</span>
        );
      },
    },
    {
      name: "Баланс",
      selector: (row) => row.balance,
      sortable: true,
      width: "150px",
      cell: (row) => {
        return `${row.currency === 'RUB' ? row.balance : row.balanceUsdt} ${row.currency}`;
      },
    },
    {
      name: "Сумма новых (по заявкам)",
      selector: (row) => row.createdSumm,
      sortable: true,
      width: "200px",
      cell: (row) => {
        return (
          <span>{isNaN(parseFloat(row.createdSumm)) ? '0 RUB' : parseFloat(row.createdSumm).toFixed(2) + ' RUB'}</span>
        );
      },
    },
    {
      name: "Сумма в работе (по заявкам)",
      selector: (row) => row.pendingSumm,
      sortable: true,
      width: "200px",
      cell: (row) => {
        return (
          <span>{isNaN(parseFloat(row.pendingSumm)) ? '0 RUB' : parseFloat(row.pendingSumm).toFixed(2)+' RUB'}</span>
        );
      },
    },
    {
      name: "Корректировка",
      ignoreRowClick: false,
      button: true,
      width: "170px",
      cell: (row) => {
        return (
          <button
            className="btn-line btn-line-primary btn-line-primary-hover"
            onClick={() => handler(row.id)}
          >
            Добавить
          </button>
        );
      },
    },
    {
      name: "ФИО",
      selector: (row) => row.fullname,
      sortable: true,
      width: "180px",
    },
    {
      name: "Последний вход",
      selector: (row) => row.lastAuth,
      width: "240px",
    },
  ];

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}}/>
      );
    }
  };

  return (
    <>
      {renderRedirect()}
      {loading && <Loader/>}
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className=" d-flex justify-content-between align-items-center flex-wrap w-100">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title media-fs-17" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {UserCunsumer.setSidebarOpen(true)}}/>
                        Пользователи и их балансы
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <ModalEditBallance
              updateData={() => updateData()}
              togglePopUp={togglePopUp}
              show={showEditUserModal}
            />
            <div className="row d-flex justify-content-between align-items-center flex-wrap grid-margin">
              <div className="col-md-4 col-12">
                <p className="text-muted my-2">Все пользователи
                  <Users className="link-icon ms-2 mb-1" style={{width: '16px'}}/>
                </p>
              </div>  
            </div>
            {!isEmpty(userFilter('user')) && 
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title text-warning">Юзеры
                            <User className="link-icon ms-2 mb-1" style={{width: '16px'}}/>
                            <span className="text-muted ms-2" style={{textTransform: 'none'}}>(мерчанты/автовыплаты)</span>
                          </h6>
                          <div className="table-responsive">
                            <div
                              id="dataTableExample_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <DataTable
                                title={false}
                                columns={columns}
                                data={userFilter('user')}
                                highlightOnHover={true}
                                striped={true}
                                pointerOnHover={true}
                                className={"table dataTable"}
                                pagination={true}
                                noHeader={true}
                                theme="solarized"
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[
                                  50,
                                  100,
                                  200,
                                  300,
                                  400,
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {!isEmpty(userFilter('manager')) && 
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title text-success">Менеджеры
                            <Pocket className="link-icon ms-2 mb-1" style={{width: '16px'}}/>
                          </h6>
                          {isEmpty(UserCunsumer.users) ? (
                            <Loader />
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="dataTableExample_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer"
                              >
                                <DataTable
                                  title={false}
                                  columns={columns}
                                  data={userFilter('manager')}
                                  highlightOnHover={true}
                                  striped={true}
                                  pointerOnHover={true}
                                  className={"table dataTable"}
                                  pagination={true}
                                  noHeader={true}
                                  theme="solarized"
                                  paginationPerPage={50}
                                  paginationRowsPerPageOptions={[
                                    50,
                                    100,
                                    200,
                                    300,
                                    400,
                                  ]}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {!isEmpty(userFilter('trader')) && 
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title text-light">Трейдеры
                            <Pocket className="link-icon ms-2 mb-1" style={{width: '16px'}}/>
                          </h6>
                          {isEmpty(UserCunsumer.users) ? (
                            <Loader />
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="dataTableExample_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer"
                              >
                                <DataTable
                                  title={false}
                                  columns={columns}
                                  data={userFilter('trader')}
                                  highlightOnHover={true}
                                  striped={true}
                                  pointerOnHover={true}
                                  className={"table dataTable"}
                                  pagination={true}
                                  noHeader={true}
                                  theme="solarized"
                                  paginationPerPage={50}
                                  paginationRowsPerPageOptions={[
                                    50,
                                    100,
                                    200,
                                    300,
                                    400,
                                  ]}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {!isEmpty(userFilter('admin')) && 
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title text-danger">Админы
                            <Key className="link-icon ms-2 mb-1" style={{width: '16px'}}/>
                          </h6>
                          {isEmpty(UserCunsumer.users) ? (
                            <Loader />
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="dataTableExample_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer"
                              >
                                <DataTable
                                  title={false}
                                  columns={columns}
                                  data={userFilter('admin')}
                                  highlightOnHover={true}
                                  striped={true}
                                  pointerOnHover={true}
                                  className={"table dataTable"}
                                  pagination={true}
                                  noHeader={true}
                                  theme="solarized"
                                  paginationPerPage={50}
                                  paginationRowsPerPageOptions={[
                                    50,
                                    100,
                                    200,
                                    300,
                                    400,
                                  ]}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersAdmin;