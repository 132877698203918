import React, { useContext, useEffect } from "react";
import { AdminContext } from "../../context/admin-context";
import { sendNotification } from "../../helpers/notification";
import Loader from "../Loader";

const ModalEditShowElOrder = (props) => {

  const [loading, setLoading] = React.useState(true);
  const adminCunsumer = useContext(AdminContext);
  const { show, togglePopUpShowElOrder } = props;

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  const hendlerState = async (event) => {
    await adminCunsumer.updateState(event.target.id, event.target.checked);
  };

  const submit = async () => {
    togglePopUpShowElOrder();
    await adminCunsumer.updateSettings();
    await adminCunsumer.updateState(
      "rerenderSettings",
      !adminCunsumer.rerenderSettings
    );
    sendNotification("success", "Успех", "Настройки обновлены.");
  };

  const simulateClick = React.useRef(null);

  return (
    <>
      {loading ? (
        <Loader/>
      ) : (
        <div
          className={`modal fade ${show === true ? "show" : "d-none"}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header mx-3 my-2">
                <h5 className="modal-title color-primary" style={{textTransform: 'none'}} id="exampleModalLabel">
                  Редактирование отображения заявок
                </h5>
                <button
                  type="button"
                  onClick={togglePopUpShowElOrder}
                  className="close"
                  style={{color: '#fff'}}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body mx-3 my-2">
                <form>
                  <div className="custom-control custom-switch">
                    <div>
                      <input
                        onClick={hendlerState}
                        type="checkbox"
                        className="custom-control-input"
                        id="sumAndComsa"
                        defaultChecked={
                          adminCunsumer.settings.sumAndComsa ? true : false
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="sumAndComsa"
                      >
                        Сумма с комсой
                      </label>
                    </div>
                  </div>
                  <div className="custom-control custom-switch">
                    <div>
                      <input
                        onClick={hendlerState}
                        type="checkbox"
                        className="custom-control-input"
                        id="externalId"
                        defaultChecked={
                          adminCunsumer.settings.externalId ? true : false
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="externalId"
                      >
                        External ID (Клиентский ИД)
                      </label>
                    </div>
                  </div>
                  <div className="custom-control custom-switch">
                    <div>
                      <input
                        onClick={hendlerState}
                        type="checkbox"
                        className="custom-control-input"
                        id="comments"
                        defaultChecked={
                          adminCunsumer.settings.comments ? true : false
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="comments"
                      >
                        Коммент
                      </label>
                    </div>
                  </div>
                  <div className="custom-control custom-switch">
                    <div>
                      <input
                        onClick={hendlerState}
                        type="checkbox"
                        className="custom-control-input"
                        id="dateAndTime"
                        defaultChecked={
                          adminCunsumer.settings.dateAndTime ? true : false
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="dateAndTime"
                      >
                        Дата и время
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  ref={simulateClick}
                  onClick={togglePopUpShowElOrder}
                  className="btn btn-line-danger btn-line-danger-hover btn-small"
                  data-dismiss="modal"
                >
                  Закрыть
                </button>
                <button
                  onClick={() => submit()}
                  className="btn btn-line-success btn-line-success-hover btn-small"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalEditShowElOrder;