import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import  secureLocalStorage  from  "react-secure-storage";
import { UserContext } from "../context/users-context";

import {
  List,
  Settings,
  LogOut,
  Archive,
  Home,
  Download,
  BarChart,
  AlertTriangle
} from "react-feather";

const ManagerBar = (props) => {
  
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [redirectMessage, setredirectMessage] = React.useState("");
  const userCunsumer = React.useContext(UserContext);

  useEffect(() => {
    userCunsumer.userMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    Cookies.remove("jwt");
    secureLocalStorage.removeItem("role");
    // secureLocalStorage.clear();
    setRedirectPath("/auth/login");
    setredirectMessage("👉 Вы вышли из системы.");
    setRedirect(true);
  };

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect
          to={{
            pathname: redirectPath,
            notify: {
              type: "success",
              title: "Успех ✅",
              message: redirectMessage,
            },
          }}
        />
      );
    }
  };

  const renderActiveClass = (type) => {
    if ( window.location.pathname === type ) {
      return "active";
    }
  };

  return (
    <>
      {renderRedirect()}
      <div className="sidebar-dark">
        <nav className="sidebar">
          <div className="sidebar-header">
            <Link to={`/${secureLocalStorage.getItem('role')}/home`} className="sidebar-brand" style={{width:'260px'}}>
              <img src={require("../images/logo2.png")} className="w-50" alt="" />
            </Link>
            <div className="sidebar-toggler not-active">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="sidebar-body">
            <ul className="nav">
              <li className={`nav-item ${renderActiveClass("/manager/home")}`}>
                <Link to="/manager/home" className="nav-link">
                  <Home className="link-icon" />
                  <span className="link-title">Главная</span>
                </Link>
              </li>
              <li className={`nav-item ${renderActiveClass("/manager/orders")}`}>
                <Link to="/manager/orders" className="nav-link">
                  <List className="link-icon" />
                  <span className="link-title">Заявки на выплату</span>
                </Link>
              </li>
              <li className={`nav-item ${renderActiveClass("/manager/archive")}`}>
                <Link to="/manager/archive" className="nav-link">
                  <Archive className="link-icon" />
                  <span className="link-title">Архив заявок</span>
                </Link>
              </li>
              <li className={`nav-item ${renderActiveClass("/manager/statistic")}`}>
                <Link to="/manager/statistic" className="nav-link">
                  <BarChart className="link-icon" />
                  <span className="link-title">Статистика</span>
                </Link>
              </li>
              {/* <li className={`nav-item ${renderActiveClass("/manager/withdraw")}`}>
                <Link to="/manager/withdraw" className="nav-link">
                  <Download className="link-icon" />
                  <span className="link-title">Вывод баланса</span>
                </Link>
              </li> */}
              <li className={`nav-item ${renderActiveClass("/manager/debtlist")}`}>
                <Link to="/manager/debtlist" className="nav-link">
                  <AlertTriangle className="link-icon" />
                  <span className="link-title">Чорный список</span>
                </Link>
              </li>
              <li className={`nav-item ${renderActiveClass("/manager/settings")}`}>
                <Link to="/manager/settings" className="nav-link">
                  <Settings className="link-icon" />
                  <span className="link-title">Настройки</span>
                </Link>
              </li>
              <li className="nav-item">
                <span
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  onClick={logout}
                >
                  <LogOut className="link-icon" />
                  <span className="link-title">Выход</span>
                </span>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default ManagerBar;