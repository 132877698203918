import React, { useContext, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import moment from "moment";
import Checkbox from '@material-ui/core/Checkbox';

import Loader from "../../components/Loader";
import ManagerBar from "../../components/ManagerBar";
import CheckModal from "./components/CheckModal";
import FilterOrders from "./components/FilterOrders";
import { sendNotification } from "../../helpers/notification";
import SummOrders from './components/SummOrders'

import { ManagerContext } from "../../context/manager-context";
import { UserContext } from "../../context/users-context";
import { FileText } from "react-feather";

moment.locale('ru'); 

const Archive = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [showSearch, setShowSearch] = React.useState(true);
  const [changeStatusModal, setChangeStatusModal] = React.useState(false);
  const [clearOrderArray, setClearOrderArray] = React.useState(false);
  const userCunsumer = useContext(UserContext);
  const managerCunsumer = useContext(ManagerContext);

  useEffect(() => {
    setLoading(true);
    managerCunsumer.getDebtList();
    managerCunsumer.getArchiveOrders();
    return function cleanup() {
        managerCunsumer.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(managerCunsumer.orders)) {
      setLoading(false);
    }
  }, [managerCunsumer.orders])
  
  useEffect(() => {
    if (userCunsumer.redirectMessage) {
      sendNotification("warning", "Ошибка ⚠️", userCunsumer.redirectMessage);
      userCunsumer.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCunsumer.redirectMessage]);

  const downloadInvoice = (id) => {
    managerCunsumer.getCheck(id);
    setChangeStatusModal(true);
  }

  const togglePopUp = useCallback(
    (trigger) => {
      setChangeStatusModal(false);
      managerCunsumer.updateState('checkFile', '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeStatusModal]
  );

  let columns = [
    { name: "id",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    { name: "Статус",
      selector: (row) => row.status,
      sortable: true,
      width: "140px",
      cell: (row, index, column, id) => {
        return (
          <button
            type="button"
            style={{ fontSize: 11 }}
            className={`btn-line ${
              row.status === "COMPLETED"
            ? "btn-line-success"
            : row.status === "PENDING"
            ? "btn-line-primary"
            : row.status === "ERROR"
            ? "btn-line-danger"
            : "btn-line-warning"
            }`}
          >
            {row.status === "COMPLETED"
              ? "Выполненная"
              : row.status === "PENDING"
              ? "В работе"
              : row.status === "ERROR"
              ? "Ошибочная"
              : "Новая"}
          </button>
        );
      },
    },
    { name: "Карта",
      sortable: true,
      width: "200px",
      cell: (row) => {
        return <span className={managerCunsumer.debt_list.includes(row.card) && `text-danger`}>{row.card}</span>;
      },
    },
    { name: "Сумма в RUB",
      selector: (row) => row.amount,
      sortable: true,
      width: "120px",
      cell: (row) => {
        return `${row.amount}`;
      },
    },
    { name: 'Cумма в USDT',
      selector: (row) => row.total,
      sortable: true,
      width: "130px",
      cell: (row) => {
        return `${row.total === row.amount ? 'Cумма в RUB' : `${row.total} USDT`}`;
      },
    },
    { name: "Курс",
      selector: (row) => row.course,
      sortable: true,
      width: '100px',
      cell: (row) => {
        return `${row.course}`;
      },
    },
    { name: "Банк",
      selector: (row) => row.bank,
      sortable: true,
      width: "150px",
      cell: (row) => {
        return `${row.bank}`;
      },
    },
    { ignoreRowClick: false,
      button: true,
      name: "Чек",
      width: "135px",
      cell: (row) => {
        return (
          !isEmpty(row?.metadata?.check) &&
            <button
              className="btn-line btn-line-primary btn-line-primary-hover"
              onClick={() => downloadInvoice(row.id)}
            >
              Смотреть чек
            </button>
        );
      },
    },
    { name: "Коммент:",
      selector: (row) => row.comment,
      width: "240px",
      cell: (row) => {
        return `${row.comment}`;
      },
    },
    { name: "Доп. Коммент:",
      selector: (row) => row.comment2,
      width: "180px",
      cell: (row) => {
        return `${row.comment2}`;
      },
    },
    { name: "Дата создания",
      sortable: true,
      width: "185px",
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    { name: "Выполненная в",
      sortable: true,
      width: "185px",
      cell: (row) => {
        return (
          <span>{row.successedAt ? moment(row.successedAt).format("D/MM/YYYY, H:mm:ss") : '-------'}</span>
        );
      },
    },
  ];

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const tableData = {
    columns: columns,
    data: managerCunsumer.orders,
    export: false,
    print: false,
    filter: showSearch,
    filterPlaceholder: 'Универсальный поиск...'
  };

  const handleClearSelected = async (bool) => {
    await setClearOrderArray(true);
    await managerCunsumer.updateState('orderArray', []);
    await setShowSearch(true);
    await setClearOrderArray(false);
  }

  const hendleSelected = async (state) => {
    await setShowSearch(false);
    await managerCunsumer.updateState('orderArray', state.selectedRows);
    if (isEmpty(managerCunsumer.orderArray)) {
      await setShowSearch(true);
    }
  }

  return (
    <>
      {userCunsumer.redirect && userCunsumer.redirectPath && <Redirect to={{ pathname: userCunsumer.redirectPath,}}/>}
      {loading && isEmpty(userCunsumer.user) ? (
        <Loader />
      ) : (
        <div className="main-wrapper">
          <ManagerBar />
          <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
            <nav  className="navbar" style={{height: '60px'}}>
              <div  className="navbar-content">
                <form  className="search-form">
                  <div  className="input-group">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <div>
                        <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                          История заявок
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </nav>
            <div className="page-content">
              <CheckModal
                togglePopUp={togglePopUp}
                show={changeStatusModal}
              />
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className="row">
                    <div className="col-md-6 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <FilterOrders />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <SummOrders handleClearSelected={() => handleClearSelected() } />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title color-primary">
                        Выполненные заявки
                        <FileText className="link-icon ms-1 mb-1" style={{width: '16px'}}/>
                      </h6>
                      <div className="table-responsive">
                        <div
                          id="dataTableExample_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <DataTableExtensions {...tableData} >
                            <DataTable
                              title={false}
                              style={{color: 'white'}}
                              columns={columns}
                              highlightOnHover={true}
                              striped={true}
                              pointerOnHover={true}
                              className={"table dataTable"}
                              pagination={true}
                              noHeader={true}
                              theme="solarized"
                              paginationPerPage={50}
                              paginationRowsPerPageOptions={[
                                50,
                                100,
                                200,
                                300,
                                400,
                              ]}
                              selectableRowsComponent={Checkbox}
                              selectableRowsComponentProps={{ color: 'primary' }}
                              selectableRows={true}
                              selectableRowsVisibleOnly={true}
                              clearSelectedRows={clearOrderArray}
                              selectableRowsHighlight={true}
                              onSelectedRowsChange={hendleSelected}
                            />
                          </DataTableExtensions>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Archive;