import React, { useContext } from "react";
import { isEmpty } from "lodash";

import Loader from "../../../components/Loader";
import { ManagerContext } from "../../../context/manager-context";

const CheckModal = (props) => {

  const managerCunsumer = useContext(ManagerContext);
  const { show } = props;

  return (
    <div
        className={`modal fade ${show === true ? "show" : "d-none"}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content px-3">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Чек
                    </h5>
                    <button
                        type="button"
                        onClick={props.togglePopUp}
                        className="close text-white"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body text-center">
                    { !isEmpty(managerCunsumer.checkFile) ? (
                        <img alt="img" src={managerCunsumer.checkFile} height={'600px'} width={'auto'} />
                    ) : (
                        <Loader />
                    )}
                </div>
                <div className="modal-footer">
                    <button
                        onClick={props.togglePopUp}
                        type="button"
                        className="btn btn-two btn-small"
                        data-dismiss="modal"
                    >
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default CheckModal;