import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";
import  secureLocalStorage  from  "react-secure-storage";
// Scss
import "./scss/katalina.scss";
// Pages admin
import Archive from "./pages/admin/Archive";
import Home from "./pages/admin/Home";
import UsersAdmin from "./pages/admin/UsersAdmin";
import AdminCorrections from "./pages/admin/AdminCorrections";
import CommentSumm from "./pages/admin/CommentSumm";
import ExportOrders from "./pages/admin/ExportOrders";
import White from "./pages/admin/White";
import SendCallback from "./pages/admin/sendCallback";
import AdminSettings from "./pages/admin/AdminSettings";
import UsersWithdraws from "./pages/admin/UsersWithdraws";
import AdminTraderOrders from "./pages/admin/AdminTraderOrders";
// user pages
import UserWhite from "./pages/user/White";
import UserHome from "./pages/user/UserHome";
import UserCreateOrder from "./pages/user/UserCreateOrder";
import UserSettings from "./pages/user/UserSettings";
import UserStatistic from "./pages/user/UserStatistic";
// manager pages
import ManagerWhite from "./pages/manager/White";
import ManagerHome from "./pages/manager/Home";
import ManagerOrders from "./pages/manager/Orders";
import ManagerStatistic from "./pages/manager/Statistic";
import ManagerArchiveOrders from "./pages/manager/Archive";
import ManagerSettings from "./pages/manager/Settings";
import ManagerDebtlist from "./pages/manager/Debtlist";
// trader pages
import TraderHome from "./pages/trader/Home";
import TraderOrders from "./pages/trader/Orders";
import TraderArchiveOrders from "./pages/trader/Archive";
import TraderStatistic from "./pages/trader/Statistic";
import TraderSettings from "./pages/trader/Settings";
import TraderWithdraw from "./pages/trader/Withdraw";
// system pages
import Welcome from "./pages/Home";
import NoRoute from "./pages/NoRoute";
import Login from "./pages/Login";
import Forbidden from "./pages/Forbidden";
// context

const PrivateRoute = ({ component, role, ...options }) => {
  let userRole = secureLocalStorage.getItem("role");
  if (isEmpty(Cookies.get("jwt"))) {
    var user = false;
  } else {
    user = true;
  }

  if (user && userRole === role) {
    return <Route {...options} component={component} />
  } else if (user && userRole !== role) {
    return <Redirect
      push
      to={{
        pathname: '/forbidden'
      }}
    />
  } else {
    return <Redirect
      push
      to={{
        pathname: '/auth/login'
      }}
    />
  }
};

export default class App extends React.Component {

  render() {
    return (
      <Router>
        <Switch>
          {/* user routes */}
          <PrivateRoute exact role="user" path="/user/" component={UserWhite} props={this} />
          <PrivateRoute exact role="user" path="/user/home" component={UserHome} />
          <PrivateRoute exact role="user" path="/user/create-order" component={UserCreateOrder} />
          <PrivateRoute exact role="user" path="/user/settings" component={UserSettings} />
          <PrivateRoute exact role="user" path="/user/statistic" component={UserStatistic} />
          {/* manager routes */}
          <PrivateRoute exact role="manager" path="/manager/" component={ManagerWhite} props={this} />
          <PrivateRoute exact role="manager" path="/manager/home" component={ManagerHome} props={this} />
          <PrivateRoute exact role="manager" path="/manager/orders" component={ManagerOrders} />
          <PrivateRoute exact role="manager" path="/manager/statistic" component={ManagerStatistic} />
          <PrivateRoute exact role="manager" path="/manager/archive" component={ManagerArchiveOrders} />
          <PrivateRoute exact role="manager" path="/manager/settings" component={ManagerSettings} />
          <PrivateRoute exact role="manager" path="/manager/debtlist" component={ManagerDebtlist} />
          {/* trader routes */}
          <PrivateRoute exact role="trader" path="/trader/" component={TraderHome} props={this} />
          <PrivateRoute exact role="trader" path="/trader/home" component={TraderHome} props={this} />
          <PrivateRoute exact role="trader" path="/trader/orders" component={TraderOrders} />
          <PrivateRoute exact role="trader" path="/trader/archive" component={TraderArchiveOrders} />
          <PrivateRoute exact role="trader" path="/trader/statistic" component={TraderStatistic} />
          <PrivateRoute exact role="trader" path="/trader/settings" component={TraderSettings} />
          <PrivateRoute exact role="trader" path="/trader/withdraw" component={TraderWithdraw} />
          {/* admin routes */}
          <PrivateRoute exact role="admin" path="/admin/" component={White} props={this} />
          <PrivateRoute exact role="admin" path="/admin/archive" component={Archive} props={this} />
          <PrivateRoute exact role="admin" path="/admin/last-orders" component={Home} props={this} />
          <PrivateRoute exact role="admin" path="/admin/corrections" component={AdminCorrections} />
          <PrivateRoute exact role="admin" path="/admin/users" component={UsersAdmin} />
          <PrivateRoute exact role="admin" path="/admin/user-settings" component={AdminSettings} />
          <PrivateRoute exact role="admin" path="/admin/orders/comment/summ" component={CommentSumm} />
          <PrivateRoute exact role="admin" path="/admin/orders/export" component={ExportOrders} />
          <PrivateRoute exact role="admin" path="/admin/orders/sendcallback" component={SendCallback} />
          <PrivateRoute exact role="admin" path="/admin/users-withdraws" component={UsersWithdraws} />
          <PrivateRoute exact role="admin" path="/admin/trader_orders" component={AdminTraderOrders} />
          {/* system routes */}
          <Route exact path="/" component={Welcome} />
          <Route exact path="/forbidden" component={Forbidden} />
          <Route exact path="/auth/login" component={Login} />
          <Route component={NoRoute} />
        </Switch>
      </Router>
    );
  }
}
