import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { LogOut } from 'react-feather';

  const Forbidden = () => {
    
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    return(
      <>
        {loading &&
          <Loader/>
        }
        <div className="main-wrapper">
          <div className="page-wrapper full-page" style={{backgroundColor:'rgb(210, 207, 207)'}}>
            <div className="page-content d-flex align-items-center justify-content-center p-0">
              <div className="row w-100 mx-0 auth-page">
                <div className="col-md-6 col-xl-6 mx-auto d-flex flex-column align-items-center">
                  <img width={400} src={require('../images/tokendetails.png')} className="img-fluid mb-2" alt="404" />
                  <h1 className="font-weight-bold mb-22 mt-2 tx-80 text-muted">403</h1>
                  <h4 className="mb-2 text-dark">Доступ запрещен</h4>
                  <Link to={'/'} className="btn btn-two btn-small mt-2">Вернуться на главную <LogOut className="ml-1" size={18}/></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  };

export default Forbidden;