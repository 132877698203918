import React from "react";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import moment from "moment";
import { isEmpty } from "lodash";

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import { sendNotification } from "../../helpers/notification";
import { ManagerContext } from "../../context/manager-context";
import { UserContext } from "../../context/users-context";
import {AlignJustify} from "react-feather";

moment.locale('ru'); 

const UsersWithdraws = (props) => {

  const [loading, setLoading] = React.useState(true);
  // const showSearch = React.useState(true);
  const [showFrom, setShowForm] = React.useState(false);
  const managerCunsumer = React.useContext(ManagerContext);
  const userCunsumer = React.useContext(UserContext);
  

  React.useEffect(() => {
    managerCunsumer.getUsersWithdraws();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const hendler = async (event) => {
		await managerCunsumer.updateState(event.target.name, event.target.value);
  };

	const changeWithdrawStatus = async (id, amount, user_id) => {
		await managerCunsumer.updateState('withdraw_id', id);
		await managerCunsumer.updateState('withdraw_amount', amount);
		await managerCunsumer.updateState('withdraw_user_id', user_id);
		setShowForm(true);
	};

  const submit = async (e) => {
    e.preventDefault();
		if (!['COMPLETED', 'ERROR'].includes(managerCunsumer.withdraw_status)) {
			sendNotification("danger", "Ошибка", `Не верный статус`);
			return;
		}
		if (window.confirm(`Вы уверены что изменить статус заявки на выплату ID: ${managerCunsumer.withdraw_id}, на ${managerCunsumer.withdraw_status === 'COMPLETED' ? 'Выплаченая' : 'Ошибка'} ?`)) {
			setLoading(true);
			await managerCunsumer.updateWithdrawStatus().then(() => {
				managerCunsumer.getUsersWithdraws();
				setLoading(false);
			});
    }
  };

  let columns = [
    {
      name: "ID заявки",
      selector: (row) => row.id,
      sortable: true,
      width: "105px",
    },
    {
      name: "ID юзера",
      selector: (row) => row.userId,
      sortable: true,
      width: "100px",
    },
    {
      name: "Тип",
      selector: (row) => row.metadata.type,
      sortable: true,
      width: "120px",
      cell: (row) => {
        return (
          <span
            style={{ fontSize: 14 }}
            className={`text-info`}
          >
            {row.metadata.type === "wallet" ? 'На кошелек' : 'Наличные'}
          </span>
        );
      },
    },
    {
      name: "Сумма",
      selector: (row) => row.amount,
      sortable: true,
      width: "130px",
      cell: (row) => {
        return `${row.amount}`;
      },
    },
    {
      name: "Статус",
      selector: (row) => row.status,
      sortable: true,
      width: "120px",
      cell: (row ) => {
        return (
          <button
            style={{ fontSize: 11}}
            className={`btn-line ${
              row.status === "COMPLETED"
                ? "btn-line-success"
                : row.status === "PENDING"
                ? "btn-line-primary"
                : row.status === "ERROR"
                ? "btn-line-danger"
                : "btn-line-warning"
            }`}
          >
            {row.status === "COMPLETED"
              ? "Выплачено"
              : row.status === "PENDING"
              ? "Обработка"
              : row.status === "ERROR"
              ? "Ошибка"
              : "Новая"}
          </button>
        );
      },
    },
    {
      name: "Кошелек",
      sortable: true,
      width: "450px",
      cell: (row) => {
        return <span>{row.card}</span>;
      },
    },
    {
      ignoreRowClick: false,
      button: true,
      name: "Действие",
      width: "145px",
      cell: (row) => {
        return (
            <button
            className="btn-line btn-line-success btn-line-success-hover"
            onClick={() => changeWithdrawStatus(row.id, row.amount, row.userId)}
          >
            Изменить статус
          </button>
        );
      },
    },
    {
      name: "Коммент:",
      selector: (row) => row.comment,
      width: "220px",
      cell: (row) => {
        return `${row.comment}`;
      },
    },
    {
      name: "Дата и время",
      sortable: true,
      width: "185px",
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
  ];

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const tableData = {
    columns: columns,
    data: managerCunsumer.users_withdraw_orders,
    export: false,
    print: false,
    filter: true,
    filterPlaceholder: 'Универсальный поиск...'
  };

  return (
    <>
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className=" d-flex justify-content-between align-items-center flex-wrap w-100">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title media-fs-17" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Заявки на вывод баланса
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <p style={{textTransform: 'uppercase'}} className="text-muted">Обработайте выполненные заявки</p>
              </div>
            </div>
						{showFrom === true && 
							<div className="row mb-4">
								<div className="col-md-5 stretch-card">
									<div className="card">
										<div className="card-body">
											<h6 className="card-title text-primary">
												Изменить статус выплаты 
											</h6>
											<hr />
											<form onSubmit={(e) => submit(e)}>
												<h6 className="mb-2"><span style={{color: 'rgb(164, 180, 195)'}}>ID заявки:</span>  {managerCunsumer.withdraw_id}</h6>
												<h6 className="mb-2"><span style={{color: 'rgb(164, 180, 195)'}}> Сумма выплаты: </span> {managerCunsumer.withdraw_amount}</h6>
												<h6 className=""><span style={{color: 'rgb(164, 180, 195)'}}> ID Пользователя:</span>  {managerCunsumer.withdraw_user_id}</h6>
												<hr />
                        <div className="form-group mt-3 ">
                          <label style={{color: 'rgb(164, 180, 195)'}} htmlFor="select">Выберите новый статус</label>
                          <select className="form-control" id="withdraw_status" name="withdraw_status" onChange={hendler}>
                            <option defaultValue>Выбрать</option>
                            <option value="COMPLETED">Выплачено</option>
                            <option value="ERROR">Ошибка</option>
                          </select>
                        </div>
                        <button className="btn btn-line-success btn-line-success-hover btn-small mt-3" type="submit">Подтвердить</button>
											</form>
										</div>
									</div>
								</div>
							</div>
						}
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
								<div className="card">
									<div className="card-body">
										{loading ? (
												<Loader />
											) : (
												<>
													{isEmpty(managerCunsumer.users_withdraw_orders) ? (
                            <h5 className="text-center text-muted py-5" style={{textTransform: 'none'}}>Заявок менеджеров на вывод пока нету</h5>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="dataTableExample_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer"
                              >
                                <DataTableExtensions {...tableData} >
                                  <DataTable
                                    title={false}
                                    columns={columns}
                                    highlightOnHover={true}
                                    striped={true}
                                    pointerOnHover={true}
                                    className={"table dataTable"}
                                    pagination={true}
                                    noHeader={true}
                                    theme="solarized"
                                    paginationPerPage={50}
                                    paginationRowsPerPageOptions={[
                                      50,
                                      100,
                                      200,
                                      300,
                                      400,
                                    ]}
                                  />
                                </DataTableExtensions>
                              </div>
                            </div>
                          )}
                    		</>
                			)}
              		</div>
              	</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersWithdraws;
