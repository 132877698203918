import React, { useContext, useState, useEffect, useCallback } from "react";
import { ManagerContext } from "../../context/manager-context";
import { sendNotification } from "../../helpers/notification";
import { isEmpty } from "lodash";
import Loader from "../Loader";
import { useDropzone } from 'react-dropzone';
import  secureLocalStorage  from  "react-secure-storage";

import { Download } from "react-feather";

const MultiChangeModal = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [proccessing, setProccessing] = React.useState(false);
  const [paths, setPaths] = useState([]);
  const [checkError, setCheckError] = useState(false);
  
  const managerCunsumer = useContext(ManagerContext);
  const { show } = props;

  useEffect(() => {
    setLoading(false);

    return () => {
      setPaths([])
      managerCunsumer.updateState('checksArray', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateField = async (event) => {
    if (event.target.name === 'skipcheck') {
      await secureLocalStorage.setItem("skipcheck", event.target.checked);
    } else {
      await managerCunsumer.updateState(event.target.name, event.target.value);
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    setCheckError(false)
    managerCunsumer.updateState('checksArray', acceptedFiles);
    setPaths(acceptedFiles.map(file => URL.createObjectURL(file)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPaths]) 
  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop, multiple: true})

  const submit = async () => {
    if (isEmpty(managerCunsumer.status)) {
      sendNotification("danger", "Ошибка", `Не выбрали статус.`);
      return;
    }

    if (secureLocalStorage.getItem("skipcheck") !== true && managerCunsumer.status === "COMPLETED" && isEmpty(managerCunsumer.checksArray)) {
      setCheckError(true);
      sendNotification("danger", "Прикрепите чеки", "Для статуса Выполненная - необходимо прикрепить чеки");
      return;
    }

    if (secureLocalStorage.getItem("skipcheck") !== true && managerCunsumer.status === "COMPLETED" && managerCunsumer.checksArray.length !== managerCunsumer.ordersArray.length) {
      setCheckError(true);
      sendNotification("danger", "Не верное количество чеков", `Вы прикрепили ${managerCunsumer.checksArray.length} чека, а нужно ${managerCunsumer.ordersArray.length}`);
      return;
    }

    if (window.confirm(`Подтверждаете обработку заявок: ${JSON.stringify(managerCunsumer.ordersArray)}`)) {
      setProccessing(true);

      await managerCunsumer.multiOrdersUpdate();
      setPaths([]);
      setProccessing(false);
      props.updateData();
      simulateClick.current.click();
    }
  };

  const simulateClick = React.useRef(null);

  const handleClose = () => {
    setPaths([]);
    props.togglePopUp();
  }

  return (
    <>
      {loading ? (
        <Loader/>
      ) : (
        <div
          className={`modal fade ${show === true ? "show" : "d-none"}`}
          tabIndex="-1"
          style={{overflow: 'scroll'}}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content  p-3">
              <div className="modal-header">
                <h5 className="modal-title">
                  Действия
                </h5>
                <button
                  type="button"
                  onClick={handleClose}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span style={{color: 'white'}} aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-white">
                <h5 style={{ textTransform: 'none'}} className="text-center mb-4">Сменить статусы заявок из пачки:</h5>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group" style={{ fontSize: 16 }}>
                        <p className="mb-1"><strong>Заявки:</strong></p>
                        {managerCunsumer.ordersArray.map((item, index) => {
                          let info = managerCunsumer.orders.filter((i) => i.id === item)[0];
                          return <p key={index} className="mb-1">{managerCunsumer.multiDoned.includes(item) && `✅`} ID: {item}, {managerCunsumer.debt_list.includes(info.card) ? <span className="text-danger">{info.card}</span> : info.card}, {info.amount} руб.</p>
                        })}
                      </div>
                      {proccessing === false ? (
                        <>
                          <div className="form-group">
                            <label style={{ fontSize: 16 }} htmlFor="status">
                              <strong>Иземнить статус на:</strong>
                            </label>
                            <select
                              onChange={updateField}
                              value={managerCunsumer.status ? managerCunsumer.status : ''}
                              className="form-control"
                              name="status"
                              id="status"
                              required
                            >
                              <option defaultValue>
                                Выбирите статус
                              </option>
                              <option value={"ERROR"}>Ошибочная</option>
                              <option value={"COMPLETED"}>Выполненная</option>
                            </select>
                          </div>
                          { !isEmpty(managerCunsumer.status) && managerCunsumer.status === "COMPLETED" &&
                            <>
                              <div {...getRootProps()} className={`fileDropzone mt-2 mb-4 ${checkError ? 'error-border' : !isEmpty(managerCunsumer.checksArray) ? 'success-border' : ''}`} >
                                <input {...getInputProps()} />
                                {isDragActive ? <p>Перетащите чеки сюда ...</p> :
                                  !isEmpty(paths) ? (
                                    <div>
                                      {
                                        paths.map(path => 
                                          <img key={path} src={path} height={'auto'} width={'80px'} className="p-1" alt="check" />
                                        )
                                      }
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setPaths([]);
                                          managerCunsumer.updateState('checksArray', []);
                                        }}
                                        className="close text-white"
                                        style={{ right: '10px', position: 'relative' }}
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      <div className={`text-center mb-3 ${checkError ? 'text-danger' : 'text-muted'}`}>
                                        <Download size={48} />
                                      </div>
                                      <h6 className={`mb-2 ${checkError ? 'text-danger' : ''}`}>Прикрепите {managerCunsumer.ordersArray.length} чеков</h6>
                                      <p>Перетащите чеки сюда, или кликните для выбора файлов</p>
                                    </>
                                  )
                                }
                              </div>
                              <div className="form-check form-check-flat form-check-primary mb-4">
                                <label className="form-check-label">
                                  <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    onChange={updateField}
                                    name="skipcheck"
                                    defaultChecked={ secureLocalStorage.getItem("skipcheck") ? secureLocalStorage.getItem("skipcheck") : false }
                                  />
                                  Пропустить чеки
                                  <i className="input-frame"></i>
                                </label>
                              </div>
                            </>
                          }
                        </>
                      ) : (
                        <div className="text-center my-5">
                          <img src={require('../../images/loading.gif')} width='70px' alt="" />
                        </div>
                      )}
                      
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  ref={simulateClick}
                  onClick={handleClose}
                  type="button"
                  className="btn btn-gray btn-small"
                  data-dismiss="modal"
                >
                  Закрыть
                </button>
                {proccessing === false ? (
                  <button
                    onClick={(e) => submit(e)}
                    className={`btn btn-green btn-small`}
                  >
                    Сохранить
                  </button>
                ) : (
                  <button
                    disabled
                    className={`btn btn-green btn-small`}
                  >
                    Обработка...
                  </button>
                )}
                
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MultiChangeModal;