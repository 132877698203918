import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { sendNotification } from "../../helpers/notification";
import Loader from "../../components/Loader";
import ManagerBar from "../../components/ManagerBar";

import { ManagerContext } from "../../context/manager-context";
import { isEmpty } from "lodash";

const Settings = () => {
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");

  const managerCunsumer = useContext(ManagerContext);

  React.useEffect(() => {
    managerCunsumer.getDebtList();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (managerCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", managerCunsumer.redirectMessage);
      setRedirectPath(managerCunsumer.redirectPath);
      managerCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerCunsumer.redirect]); 

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{pathname: redirectPath,}}/>
      );
    }
  };

  const hendler = async (event) => {
		await managerCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async (e) => {
    e.preventDefault();
		if (isEmpty(managerCunsumer.card_to_debt)) {
			sendNotification("danger", "Ошибка", `Карта не может быть пустой`);
			return;
		}
    setLoading(true);
    managerCunsumer.addCardToDebt().then(() => {
      managerCunsumer.getDebtList();
      setLoading(false);
    });
  };

  return (
    <>
      {renderRedirect()}
      {loading && <Loader/>}
      <div className="main-wrapper">
        <ManagerBar />
        <div className="page-wrapper">
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        Черный список карт
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content" style={{backgroundColor: '#070d19'}}>
            <div className="row d-flex justify-content-start">
              <div className="col-md-4 grid-margin stretch-card">
                <div className="card">
                  <div style={{padding: '20px'}}  className="card-body pb-1" >
                    <h6 className="card-title-dark">
                      Карты в черном списке ⛔️ 
                    </h6>
                    <hr className="mt-3 mb-4"/>
                    { !isEmpty(managerCunsumer.debt_list) ? (
                      managerCunsumer.debt_list.map((item, index) => {
                        return <p key={index} className="mb-1 text-white">{item}</p>
                      })
                    ) : (
                      <h6>Спикос пока что пустой.</h6>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div style={{padding: '20px'}}  className="card-body pb-1" >
                    <h6 className="card-title-dark">
                      Добавить карту в черный список 
                    </h6>
                    <hr className="mt-3 mb-4"/>
                    <form
                      className="forms-sample"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className="form-group">
                        <label style={{color: '#eef4ff'}}  htmlFor="card_to_debt">
                          <b>Карта</b>
                        </label>
                        <input
                          type="text"
                          name="card_to_debt"
                          onChange={hendler}
                          required
                          placeholder=""
                          className="form-control"
                          value={managerCunsumer.card_to_debt ? managerCunsumer.card_to_debt : '' }
                          />
                      </div>
                      <div className="mt-3">
                        <button
                          className={`btn btn-line-primary btn-line-primary-hover btn-small mt-3`}
                          onClick={(e) => submit(e)}
                        >
                          Сохранить
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;