import React, { createContext } from "react";
import { Auth } from "../helpers/api";
import Cookies from "js-cookie";
import  secureLocalStorage  from  "react-secure-storage";

export const AuthContext = createContext();

const setToken = (token) => {
  Cookies.set("jwt", token, { expires: 1 });
};

export class AuthProvider extends React.Component {
  state = {
    setUser: (user) => this.setState({ user }),
    login: () => {
      return Auth.login(
        this.state.email,
        this.state.password,
        this.state.code
      ).then((res) => {
        setToken(res.body.token);
        secureLocalStorage.setItem("role", res.body.user.role);
        this.state.setUser(res.body.user);
        return res.body.user;
      });
    },
    updateState: async (key, val) =>
      await this.setState((state) => {
        state[key] = val;
        return state;
      }),
    user: {},
    email: "",
    password: "",
    code: null,
    merchant: {},
  };

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export const AuthConsumer = AuthContext.Consumer;
