import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/users-context";
import { sendNotification } from "../../helpers/notification";
import Loader from "../Loader";

const ModalEditBallance = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState();
  const userCunsumer = useContext(UserContext);
  const { show } = props;

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    let user = userCunsumer.users.filter((obj) => {
      return obj.id === userCunsumer.userToEdit;
    });
    setUser(user[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCunsumer.userToEdit]); 

  const updateField = async (event) => {
    await userCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    if (window.confirm(`Вы уверены, что хотите добавить пользователю - ${userCunsumer.balanceAmount} ${user.currency} и Вы точно не ошиблись валютой?`)) {
      return userCunsumer
        .updateBallance()
        .then(props.updateData())
        .then(() => sendNotification("success", "Успех", "Баланс обновлен."))
        .then(simulateClick.current.click())
        .catch((e) => {
          console.log("e", JSON.stringify(e));
          if (e.response && e.response.body.message) {
            sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
          }
        });
    }
  };

  const simulateClick = React.useRef(null);

  return (
    <>
      {loading ? (
        <Loader/>
      ) : (
        <div
          className={`modal fade ${show === true ? "show" : "d-none"}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header mx-3 my-2">
                <h5 className="modal-title media-fs-17" style={{textTransform: 'none'}} id="exampleModalLabel">
                  Корректировка баланса для <br/>
                  <span className="text-primary">{user && user.email}</span>
                </h5>
                <button
                  type="button"
                  onClick={props.togglePopUp}
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body mx-3 my-1">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group" style={{ fontSize: 16 }}>
                        <p className="mb-1">
                          ID пользователя: 
                          <span style={{color: '#fff'}}> {user && user.id}</span>
                        </p>
                        <p className="mb-1">Email: 
                        <span style={{color: '#fff'}}> {user && user.email}</span>
                        </p>
                        <p className="mb-1">
                          Текущий баланс: 
                          {' '}
                          <span style={{color: '#fff'}}> 
                            {user && user.currency === 'RUB' ? user?.balance : user?.balanceUsdt}
                            {' '}
                            {user && user.currency }
                          </span>
                        </p>
                      </div>
                      <div className="form-group">
                        <label style={{ fontSize: 16, color:'#a4b4c3' }} htmlFor="balanceAmount">
                          <strong>Прибавить к {user && user.currency } балансу:</strong>
                        </label>
                        <input
                          onChange={updateField}
                          className="form-control"
                          name="balanceAmount"
                          id="balanceAmount"
                          placeholder={`Пример: 100000`}
                          value={userCunsumer.balanceAmount ? userCunsumer.balanceAmount : ""}
                        />
                      </div>
                      <div className="form-group">
                        <label style={{ fontSize: 16,color:'#a4b4c3' }} htmlFor="comment">
                          <strong>Комментарий:</strong>
                        </label>
                        <input
                          onChange={updateField}
                          className="form-control"
                          name="comment"
                          id="comment"
                          value={userCunsumer.comment ? userCunsumer.comment : ""}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  ref={simulateClick}
                  onClick={props.togglePopUp}
                  className="btn btn-line-danger btn-line-danger-hover btn-small me-2"
                  data-dismiss="modal"
                >
                  Закрыть
                </button>
                <button
                  onClick={(e) => submit(e)}
                  className='btn btn-line-success btn-line-success-hover btn-small me-3'
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalEditBallance;
