import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isNull } from "lodash";
import { Database, AlignJustify } from "react-feather";

import AdminBar from "../../components/AdminBar";
import { sendNotification } from "../../helpers/notification";
import { OrderContext } from "../../context/orders-context";
import { UserContext } from "../../context/users-context";

const CommentSumm = () => {

  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const OrderCunsumer = useContext(OrderContext);
  const userCunsumer = React.useContext(UserContext);

  useEffect(() => {
    !isNull(OrderCunsumer.errorText) && sendNotification("warning", "", OrderCunsumer.errorText)
  }, [OrderCunsumer.errorText]);
  
  useEffect(() => {
    !isNull(OrderCunsumer.calculatedCommentSumm) && sendNotification("success", "Успех", "Cумма получена")
  }, [OrderCunsumer.calculatedCommentSumm]);

  useEffect(() => {
    if (OrderCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
      setRedirectPath(OrderCunsumer.redirectPath);
      OrderCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OrderCunsumer.redirect]); 

  const handler = async (event) => {
    await OrderCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    return OrderCunsumer.getSumm()
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка", e.response.body.message);
        }
      });
  };

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect
          to={{
            pathname: redirectPath,
          }}
        />
      );
    }
  };

  return (
    <>
      {renderRedirect()}
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className=" d-flex justify-content-between align-items-center flex-wrap w-100">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title media-fs-17" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Получить сумму по комменту
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row">
              <div className="col-12 col-xl-6">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group">
                        <label style={{ fontSize: 16 , color: '#fff' }} htmlFor="comment">
                          <strong className="media-fs-17">Введите комментарий по которому посчитать:</strong>
                        </label>
                        <input
                          type="text"
                          onChange={handler}
                          className="form-control"
                          placeholder="test"
                          name="commentsumm"
                          id="commentsumm"
                          style={{height: '40px'}}
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label style={{ fontSize: 16, color: '#fff'  }} htmlFor="comment">
                          <strong className="media-fs-17">От какой заявки считать? (Введите Ид):</strong>
                        </label>
                        <input
                          type="text"
                          onChange={handler}
                          className="form-control"
                          placeholder="203"
                          name="commentid"
                          id="commentid"
                          style={{height: '40px'}}
                        />
                        <p className="mt-1 text-muted" style={{ fontSize: 14}}>(Пример: если указать ID 2004, посчитает все заявки с ID больше чем 2004.)</p>
                      </div>
                      <button
                        onClick={(e) => submit(e)}
                        className={`btn btn-line-info btn-line-info-hover btn-small`}
                      >
                        Получить сумму  <Database className="link-icon" size={16} />
                      </button>
                    </form>
                    {OrderCunsumer.calculatedCommentSumm &&
                      <>
                        <h5 className="mt-4 text-muted" style={{textTransform: 'none'}}>сумма по заявкам с комментом 
                        <span style={{color: "#fff", marginTop: '10px', marginLeft: '10px'}}>"{OrderCunsumer.commentsumm}":</span></h5>
                        <h4 className='mt-4'><span style={{color: "#007bff"}}>{OrderCunsumer.calculatedCommentSumm} RUB</span> </h4>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentSumm;