import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";

import Loader from "../../components/Loader";
import TraderBar from "../../components/TraderBar";
import { sendNotification } from "../../helpers/notification";
import { TraderContext } from "../../context/trader-context";
import { UserContext } from "../../context/users-context";

const Statistic = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");

  const userCunsumer = useContext(UserContext);
  const traderCunsumer = useContext(TraderContext);

  useEffect(() => {
    setLoading(true);
    traderCunsumer.getStatistic();
    return function cleanup() {
      traderCunsumer.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  React.useEffect(() => {
    if (!isEmpty(traderCunsumer.stats)) {
      setLoading(false)
    }
  }, [traderCunsumer.stats]);

  useEffect(() => {
    if (userCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", userCunsumer.redirectMessage);
      setRedirectPath(userCunsumer.redirectPath);
      userCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCunsumer.redirect]);

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{pathname: redirectPath,}}/>
      );
    }
  };

  const ordersTotalSumDay = () => {
    let sum = traderCunsumer.stats.day.completedSum + traderCunsumer.stats.day.pendingSum;
    return sum;
  }

  const ordersCountDay = () => {
    let count = Number(traderCunsumer.stats.day.completedCount) + Number(traderCunsumer.stats.day.pendingCount);
    return count;
  }

  return (
    <>
      {renderRedirect()}
      {loading || isEmpty(traderCunsumer.stats) ? (
        <Loader />
      ) : (
        <div className="main-wrapper">
          <TraderBar />
          <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
            <nav  className="navbar" style={{height: '60px'}}>
              <div  className="navbar-content">
                <form  className="search-form">
                  <div  className="input-group">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <div>
                        <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                          Статистика
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </nav>
            <div className="page-content">
              <div className="row d-flex justify-content-between align-items-center flex-wrap grid-margin">
                <div className="col-4">
                  <p className="text-muted my-2">Тут можно посмотреть cтатистику по заявкам</p>
                </div>  
              </div>
              <div className="row mb-5">
                <div className="col-xl-10 col-lg-12">
                    <div className="section-title">
                        <h4 className="title" style={{fontSize: '25px', textTransform: 'uppercase'}}>
                          cтатистика по заявкам <span style={{color: '#727cf5'}}>за все время</span>
                        </h4>
                    </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                          <div className="col-lg-12 px-5">
                              <div className="bt-roadmap_x">
                                  <div className="bt-roadmap-wrap m-0" style={{justifyContent: 'center', minHeight: '400px'}}>
                                      <div className="bt-roadmap-item ms-5">
                                          <p className="roadmap-title"><span style={{color: '#a4b4c3'}}>заявки</span> <br/>1 000-10 000 RUB</p>
                                          <div className="roadmap-content">
                                            <span className="dot"></span>
                                            <span>Количество</span>
                                            <span style={{color: '#2ecc71'}}><strong>
                                              {traderCunsumer.stats.all.completedCountTo10000 ? traderCunsumer.stats.all.completedCountTo10000 : '0'}
                                              {'  '}шт</strong>
                                            </span>
                                          </div>
                                      </div>
                                      <div className="bt-roadmap-item bt-roadmap-item-4">
                                          <p className="roadmap-title"><span style={{color: '#a4b4c3'}}>заявки</span> <br/>10 000-100 000 RUB</p>
                                          <div className="roadmap-content">
                                            <span className="dot"></span>
                                            <span>Количество</span>
                                            <span style={{color: '#ff9700'}}><strong>
                                              {traderCunsumer.stats.all.completedCountOver10000 ? traderCunsumer.stats.all.completedCountOver10000 : '0'} 
                                              {'  '}шт</strong>
                                            </span>
                                          </div>
                                      </div>
                                      <div className="bt-roadmap-item bt-roadmap-item-3">
                                          <p className="roadmap-title">Ошибочные <span style={{color: '#a4b4c3'}}><br/>заявки</span> </p>
                                          <div className="roadmap-content">
                                            <span className="dot"></span>
                                            <span>Количество</span>
                                            <span style={{color: '#ff4581'}}><strong>
                                              {traderCunsumer.stats.all.errorCount ? traderCunsumer.stats.all.errorCount : '0'}
                                              {'  '}шт</strong>
                                            </span>
                                          </div>
                                      </div>
                                      <div className="bt-roadmap-item bt-roadmap-item-5">
                                          <p className="roadmap-title">Все <span style={{color: '#a4b4c3'}}>заявки</span> </p>
                                          <div className="roadmap-content">
                                            <span className="dot"></span>
                                            <span>Количество</span>
                                            <span style={{color: '#007ff4'}}><strong>
                                              {traderCunsumer.stats.all.completedCount ? traderCunsumer.stats.all.completedCount : '0'}{'  '} шт</strong>
                                            </span>
                                            <span>Сумма заявок</span>
                                            <span style={{color: '#007ff4'}}><strong>
                                              {traderCunsumer.stats.all.completedSum ? traderCunsumer.stats.all.completedSum : '0'}{'  '}RUB</strong>
                                            </span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row my-5">
                  <div className="col-xl-10 col-lg-12">
                      <div className="section-title">
                          <h4 className="title" style={{fontSize: '25px', textTransform: 'uppercase'}}>
                            cтатистику по заявкам <span style={{color: '#727cf5'}}>за сегодня</span>
                          </h4>
                      </div>
                  </div>
              </div>
              <div className="row">
                <div className="col-md-12 stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                          <div className="col-lg-12 px-5">
                              <div className="bt-roadmap_x">
                                  <div className="bt-roadmap-wrap m-0" style={{justifyContent: 'center', minHeight: '400px'}}>
                                      <div className="bt-roadmap-item ms-5">
                                          <p className="roadmap-title">ВЫПОЛНЕННЫЕ <span style={{color: '#a4b4c3'}}>заявки</span> </p>
                                          <div className="roadmap-content">
                                            <span className="dot"></span>
                                            <span>Количество</span>
                                            <span style={{color: '#2ecc71'}}><strong>
                                              {traderCunsumer.stats.day.completedCount ? traderCunsumer.stats.day.completedCount : '0'}
                                              {'  '}шт</strong>
                                            </span>
                                            <span>Сумма заявок</span>
                                            <span style={{color: '#2ecc71'}}><strong>
                                              {traderCunsumer.stats.day.completedSum ? traderCunsumer.stats.day.completedSum : '0'}
                                              {'  '} RUB</strong>
                                            </span>
                                          </div>
                                      </div>
                                      <div className="bt-roadmap-item bt-roadmap-item-2">
                                          <p className="roadmap-title"><span style={{color: '#a4b4c3'}}>заявки</span> В РОБОТЕ</p>
                                          <div className="roadmap-content">
                                            <span className="dot"></span>
                                            <span>Количество</span>
                                            <span style={{color: '#727cf5'}}><strong>
                                              {traderCunsumer.stats.day.pendingCount ? traderCunsumer.stats.day.pendingCount : '0'} 
                                              {'  '}шт</strong>
                                            </span>
                                            <span>Сумма заявок</span>
                                            <span style={{color: '#727cf5'}}><strong>
                                              {traderCunsumer.stats.day.pendingSum ? traderCunsumer.stats.day.pendingSum : '0'}
                                              {'  '}RUB</strong>
                                            </span>
                                          </div>
                                      </div>
                                      <div className="bt-roadmap-item bt-roadmap-item-3">
                                          <p className="roadmap-title">Ошибочные <span style={{color: '#a4b4c3'}}>заявки</span> </p>
                                          <div className="roadmap-content">
                                            <span className="dot"></span>
                                            <span>Количество</span>
                                            <span style={{color: '#ff4581'}}><strong>
                                              {traderCunsumer.stats.day.errorCount ? traderCunsumer.stats.day.errorCount : '0'}
                                              {'  '}шт</strong>
                                            </span>
                                          </div>
                                      </div>
                                      <div className="bt-roadmap-item bt-roadmap-item-5">
                                          <p className="roadmap-title">Все <span style={{color: '#a4b4c3'}}>заявки</span> </p>
                                          <div className="roadmap-content">
                                            <span className="dot"></span>
                                            <span>Количество</span>
                                            <span style={{color: '#007ff4'}}><strong>
                                              {ordersCountDay()} {'  '} шт</strong>
                                            </span>
                                            <span>Сумма заявок</span>
                                            <span style={{color: '#007ff4'}}><strong>
                                              {ordersTotalSumDay()}{'  '}RUB</strong>
                                            </span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Statistic;