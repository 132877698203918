import React from "react";
import Loader from "../../components/Loader";
import TraderBar from "../../components/TraderBar";
import ManagerWithdrawsTable from "./components/ManagerWithdrawsTable";
import { sendNotification } from "../../helpers/notification";

import { UserContext } from "../../context/users-context";
import { TraderContext } from "../../context/trader-context";

import {
  CreditCard,
  ArrowRight
} from "react-feather";

const Withdraw = () => {
  const [loading, setLoading] = React.useState(true);
  const traderCunsumer = React.useContext(TraderContext);
  const userCunsumer = React.useContext(UserContext);

  React.useEffect(() => {
    traderCunsumer.getWithdraws();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hendler = async (event) => {
    if (userCunsumer.user.currency === "USDT") {
      if (event.target.name === 'withdraw_amount' && parseFloat(event.target.value) > parseFloat(userCunsumer.user.balanceUsdt)) {
        await traderCunsumer.updateState(event.target.name, `${userCunsumer.user.balanceUsdt}`);
      } else {
        await traderCunsumer.updateState(event.target.name, event.target.value);
      }
    } else {
      if (event.target.name === 'withdraw_amount' && parseFloat(event.target.value) > parseFloat(userCunsumer.user.balance)) {
        await traderCunsumer.updateState(event.target.name, `${userCunsumer.user.balance}`);
      } else {
        await traderCunsumer.updateState(event.target.name, event.target.value);
      }
    }
		
  };

  const submit = async (e) => {
    e.preventDefault();
    if (userCunsumer.user.currency === "USDT") {
      if (parseFloat(traderCunsumer.withdraw_amount) > parseFloat(userCunsumer.user.balanceUsdt)) {
        sendNotification("danger", "Ошибка", `Вы не можете вывести ${traderCunsumer.withdraw_amount}р., так как сумма больше вашего баланса. Ваш баланс - ${userCunsumer.user.balanceUsdt}р.`);
        return;
      }
    } else {
      if (parseFloat(traderCunsumer.withdraw_amount) > parseFloat(userCunsumer.user.balance)) {
        sendNotification("danger", "Ошибка", `Вы не можете вывести ${traderCunsumer.withdraw_amount}р., так как сумма больше вашего баланса. Ваш баланс - ${userCunsumer.user.balance}р.`);
        return;
      }
    }
		
    setLoading(true);
    traderCunsumer.withdraw().then(() => {
      traderCunsumer.getWithdraws();
      setLoading(false);
    });
  };

  return (
    <>
      <div className="main-wrapper">
        <TraderBar />
        <div className="page-wrapper">
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        Заявка на вывод баланса 
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content" style={{backgroundColor: '#070d19',}}>
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div>
                <p className="text-muted">Создайте заявку на вывод или свяжитесь с поддержкой для более удобного вывода</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-xl-6">
                <div className="card">
                  <div className="card-body" >
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        <h6 className="card-title-dark">
                          Создать новую заявку на вывод <ArrowRight className="link-icon ms-2" style={{width: '18px'}}/>
                        </h6>
                        <hr />
                        <form
                          className="forms-sample"
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <div className="form-group">
                            <label style={{color: '#eef4ff'}}  htmlFor="exampleInputEmail1">
                              <b>Сумма в {userCunsumer.user.currency}</b>
                            </label>
                            <input
                              type="text"
                              name="withdraw_amount"
                              onChange={hendler}
                              placeholder="0"
                              required
                              className="form-control"
                              id="exampleInputEmail1"
                              value={traderCunsumer.withdraw_amount ? traderCunsumer.withdraw_amount : '' }
                            />
                          </div>
                          <div className="form-group">
                            <label style={{color: '#eef4ff'}}  htmlFor="exampleInputPassword1">
                              <b>Адрес USDT TRC20 кошелька</b>
                            </label>
                            <input
                              type="text"
                              name="withdraw_wallet"
                              onChange={hendler}
                              required
                              placeholder=""
                              className="form-control"
                              id="exampleInputPassword1"
                              autoComplete="current-password"
                              />
                          </div>
                          <div className="form-group">
                            <label style={{color: '#eef4ff'}}  htmlFor="exampleInputPassword1">
                              <b>Комментарий</b>
                            </label>
                            <input
                              type="text"
                              name="withdraw_comment"
                              onChange={hendler}
                              required
                              className="form-control"
                              id="exampleInputPassword1"
                              autoComplete="current-password"
                            />
                          </div>
                          <div className="mt-3">
                            <button
                              className={`btn btn-line-primary btn-line-primary-hover btn-small mt-3`}
                              onClick={(e) => submit(e)}
                            >
                              Создать заявку на вывод
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title-dark">
                      Ваш баланс <CreditCard className="link-icon ms-2" style={{width: '18px'}}/>
                    </h6>
                    <hr />
                    <h4>
                      {userCunsumer.user.currency === 'RUB' ? userCunsumer.user.balance : userCunsumer.user.balanceUsdt} {userCunsumer.user.currency}
                    </h4>
                  </div>
                </div>
              </div>              
            </div>
            <div className="row mt-4">
              <div className="col-12 col-md-12 col-xl-12 ">
                <ManagerWithdrawsTable orders={traderCunsumer.withdraw_orders} currency={userCunsumer.user.currency} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdraw;