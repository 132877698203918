import React, { useContext, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty, sortBy } from "lodash";
import moment from "moment";

import Loader from "../../components/Loader";
import ManagerBar from "../../components/ManagerBar";
import ChangeStatusModal from "../../components/modals/changeStatus";
import MultiChangeModal from "../../components/modals/multiChangeStatus";
import RangeForm from "./components/RangeForm";
import OrderCard from "../../components/OrderCard";
import { sendNotification } from "../../helpers/notification";

import { ManagerContext } from "../../context/manager-context";
import { UserContext } from "../../context/users-context";

import {
  FileText,
  Layers,
  BarChart2,
  CreditCard,
  XSquare
} from "react-feather";

moment.locale('ru'); 

const Orders = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [changeStatusModal, setChangeStatusModal] = React.useState(false);
  const [multiChange, setMultiChange] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [exportList, setExportList] = React.useState(null);
  
  const userCunsumer = useContext(UserContext);
  const managerCunsumer = useContext(ManagerContext);

  useEffect(() => {
    setLoading(true);
    managerCunsumer.getDebtList();
    managerCunsumer.getActiveOrders();
    setLoading(false);
    return function cleanup() {
      managerCunsumer.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    if (userCunsumer.redirectMessage) {
      sendNotification("warning", "Ошибка ⚠️", userCunsumer.redirectMessage);
      userCunsumer.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCunsumer.redirectMessage]);

  const handleTimerPlus = (id) => {
    managerCunsumer.timerPlus(id);
  };

  const cutOverdueOrder = (id) => {
    managerCunsumer.overdueOrder(id);
  }

  const handler = (id) => {
    managerCunsumer.updateState('activeOrder', id);
    setChangeStatusModal(true);
  };

  const togglePopUp = useCallback(
    (trigger) => {
      setChangeStatusModal(false);
      setMultiChange(false);
      managerCunsumer.updateState('status', '');
      managerCunsumer.updateState('checksArray', []);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeStatusModal]
  );

  const updateData = () => {
    managerCunsumer.clear();
    setTimeout(() => {
      managerCunsumer.getDebtList();
      managerCunsumer.getActiveOrders();
    }, 200);
  };

  const getExportList = async () => {
    setExportList(null);
    setExportLoading(true);
    let list = await managerCunsumer.getExportList();
    if (!isEmpty(list)) {
      setExportList(list)
    } else {
      setExportList('Не удалось получить список. попробуйте обновить страницу, и повторить.')
    }
    setExportLoading(false);
  }

  const getSelectedExportList = async () => {
    setExportList(null);
    setExportLoading(true);

    let orders = managerCunsumer.orders.filter(item => managerCunsumer.ordersArray.includes(item.id))

    let preSorted = sortBy(orders, ['bank']);

    let formattedStrings = preSorted.map( order => {
      if (managerCunsumer.debt_list.includes(order.card)) {
        return `⛔️ Карта в черном списке: ${order.card} ⛔️ | Банк: ${order.bank} | Сумма: ${order.amount} ${order?.metadata?.telegram ? `| telegram: ${order.metadata.telegram}` : ''}`;
      } else {
        return `Карта: ${order.card} | Банк: ${order.bank} | Сумма: ${order.amount} руб. ${order?.metadata?.telegram ? `| telegram: ${order.metadata.telegram}` : ''}`;
      }
    });

    let resultString = formattedStrings.join('\n');

    for (const order of orders) {
      await managerCunsumer.updateColor(order.id)
    }
    
    let list = await resultString;
    if (!isEmpty(list)) {
      setExportList(list)
    } else {
      setExportList('Не удалось получить список. попробуйте обновить страницу, и повторить.')
    }
    setExportLoading(false);
  }
  

  const multiClose = async (id, value) => {
    managerCunsumer.updateOrdersArray(id, value);
  }

  const updateColor = async (id) => {
    managerCunsumer.updateColor(id);
  }

  return (
    <>
      {userCunsumer.redirect && userCunsumer.redirectPath && <Redirect to={{ pathname: userCunsumer.redirectPath, }} />}
      {loading && isEmpty(userCunsumer.user) ? (
        <Loader />
      ) : (
        <div className="main-wrapper">
          <ManagerBar />
          <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
            <ChangeStatusModal
              updateData={() => updateData()}
              togglePopUp={togglePopUp}
              show={changeStatusModal}
            />
            <MultiChangeModal
              updateData={() => updateData()}
              togglePopUp={togglePopUp}
              show={multiChange}
            />
            <nav  className="navbar" style={{height: '60px'}}>
              <div  className="navbar-content">
                <form  className="search-form">
                  <div  className="input-group">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <div>
                        <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                          Заявки на выплату
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </nav>
            <div className="page-head">
              <div className="page-content" style={{backgroundColor: '#070d19', paddingBottom: '15px'}}>
                <div className="row d-flex justify-content-start">
                  <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                      <div style={{padding: '20px'}}  className="card-body pb-1" >
                        <h6 className="card-title-dark">
                          Инструкция <FileText className="link-icon ms-2" style={{width: '18px'}}/>
                        </h6>
                        <hr className="mt-3 mb-4"/>
                        <h6 className="card-title-main"><strong>1.</strong> Выберите заявку и поставте статус <strong>"В работе"</strong>.</h6>
                        <h6 className="card-title-main" ><strong>2.</strong> Переведите сумму из заявки на указанную карту из заявки.</h6>
                        <h6 className="card-title-main" ><strong>3.</strong> Измените статус заявки, если заявка успешная, <strong>прикрепите чек.</strong></h6>
                        <h6 className="card-title-main" ><strong>4.</strong> Проверьте начисление % на Ваш баланс.</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body" style={{padding: '20px'}} >
                        <h6 className="card-title-dark">
                          ЛИМИТ ОЧЕРЕДИ ЗАЯВОК <Layers className="link-icon ms-2" style={{width: '18px'}}/>
                        </h6>
                        <hr className="mt-3 mb-4"/>
                        <p className="mb-2 card-title">Лимит:
                          <span className="ms-2" style={{fontWeight: '600', color: '#007bff'}}>{userCunsumer.user.limit} шт.</span>
                        </p>
                        <p className="mb-2 card-title">Получено:
                          <span className="ms-2" style={{fontWeight: '600', color: '#007bff'}}>{managerCunsumer.orders.length} шт.</span>
                        </p>
                        <p className="mb-2 card-title">Можно еще получить:
                          <span className="ms-2" style={{fontWeight: '600', color: '#007bff'}}>{userCunsumer.user.limit - managerCunsumer.orders.length} шт.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body" style={{padding: '20px'}} >
                        <h6 className="card-title-dark">
                          ДЕЙСТВИЯ <BarChart2 className="link-icon ms-2" style={{width: '18px'}}/>
                        </h6>
                        <hr className="mt-3 mb-4"/>
                        <div className='text-center'>
                            <button 
                              className="btn btn-line-info btn-line-info-hover btn-small-xs mb-2 mt-2"
                              style={{minWidth: '190px', fontSize: '11.5px'}}
                              onClick={() => managerCunsumer.assignOrders() }
                            >
                              Получить заявки
                              <CreditCard className="link-icon ms-2 mb-1" style={{width: '14px'}}/>
                            </button>
                            <button onClick={() => managerCunsumer.clearColors()} className="btn btn-line-dark btn-line-info-hover btn-small mt-2">Очистить цвета</button>
                          </div>
                        {/* { showRangeForm === false ? ( 
                          <div className='text-center'>
                            <button 
                              className="btn btn-line-info btn-line-info-hover btn-small-xs mb-2 mt-2"
                              style={{minWidth: '190px', fontSize: '11.5px'}}
                              onClick={() => managerCunsumer.assignOrders() }
                            >
                              Получить заявки
                              <CreditCard className="link-icon ms-2 mb-1" style={{width: '14px'}}/>
                            </button>
                            <button
                              className="btn btn-line-primary btn-line-primary-hover btn-small-xs mb-2 mt-2 "
                              style={{minWidth: '190px', fontSize: '11.5px'}}
                              onClick={() => setShowRangeForm(true)}
                              >
                              изменить лимит
                              <Repeat className="link-icon ms-2 mb-1" style={{width: '14px'}}/>
                            </button>
                          </div>
                        ) : (
                          <RangeForm />
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div>
                    <p style={{textTransform: 'uppercase'}} className="text-muted">Заявки для обработки</p>
                  </div>
                </div>
                  { !isEmpty(managerCunsumer.orders) ? (
                    <>
                      {!isEmpty(exportList) &&
                        <div className="row justify-content-center mb-4">
                          <div className="col-md-8 ">
                            <div className="card text-white bg-dark px-5 py-4" style={{ whiteSpace: 'pre-wrap' }}>
                              <XSquare onClick={() => setExportList(null)} className="closeexportlist" />
                              {exportList}
                            </div>
                          </div>
                        </div>
                      }
                      <div className="row justify-content-center mb-3">
                        <div className="col-md-2 text-center">
                          { exportLoading ? 
                            (
                              <button className="btn btn-info btn-small mt-2 disabled" style={{ opacity: '1'}}>Загрузка ...</button>
                            ) : (
                              <button onClick={getExportList} className="btn btn-line-info btn-line-info-hover btn-small mt-2">Експорт всех 👉</button>
                            )
                          } 
                        </div>
                        {!isEmpty(managerCunsumer.ordersArray) && 
                          <div className="col-md-4">
                            <button onClick={() => setMultiChange(true)} className="btn btn-line-info btn-line-info-hover btn-small mt-2">Закрыть {managerCunsumer.ordersArray.length} заявок</button>
                            <button onClick={getSelectedExportList} className="btn btn-line-info btn-line-info-hover btn-small mt-2 ms-2">Експорт {managerCunsumer.ordersArray.length} заявок</button>
                          </div>
                        }
                      </div>
                    <div className="row row-cols-md-3 row-cols-lg-3 row-cols-xxl-3 row-cols-xxxl-4">
                      
                      {managerCunsumer.orders.map((item,index) => {
                        return (
                          <OrderCard 
                            key={index}
                            item={item} 
                            index={index}
                            handler={handler}
                            handleTimerPlus={handleTimerPlus}
                            cutOverdueOrder={cutOverdueOrder}
                            multiClose={multiClose}
                            colors={managerCunsumer.colors}
                            debt={managerCunsumer.debt_list.includes(item.card)}
                            updColor={updateColor}
                          />
                        )
                      })}
                    </div>
                    </>
                  ) : (
                    <div className="row d-flex justify-content-start">
                      <div className="col-md-12 stretch-card">
                        <div className="card">
                          <div className="card-body" style={{padding: '30px 20px'}} >
                              <h5 style={{textTransform: 'none'}}>Заявок нету, выберите диапазон и получите заявки</h5>
                              <hr />
                              <RangeForm />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Orders;