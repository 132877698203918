import React, { createContext } from "react";
import { Admin } from "../helpers/api";
import { isNull } from "lodash";

import { sendNotification } from "../helpers/notification";

export const AdminContext = createContext();

export class AdminProvider extends React.Component {
  state = {
    getCorrections: async () => {
      await Admin.getCorrections()
        .then((res) => {
          this.setState({ corrections: res.body.records });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          this.setState({
            redirectPath: "/",
            redirectMessage: error.response.body.message
              ? error.response.body.message
              : "Что то пошло не так. ⚠️",
            redirect: true,
          });
        });
    },

    getSettings: async () => {
      await Admin.getSettings()
        .then((res) => {
          this.setState({ settings: res.body });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          this.setState({
            redirectPath: "/",
            redirectMessage: "Что то пошло не так. ⚠️",
            redirect: true,
          });
        });
    },

    getUsersShortList: async () => {
      await Admin.getUsersShortList().then((res) => {
        this.setState({ users_short_list: res.body });
      })
      .catch((error) => {
        sendNotification("warning", "Не удалось загрузить пользователей для фильтра ⚠️", `${error.response?.body?.message}`);
      });
    },

    getPendingWithdrawsCount: async () => {
      await Admin.getPendingWithdrawsCount().then((res) => {
        this.setState({ pending_withdraws_count: res.body.count });
      })
      .catch((error) => {
        sendNotification("warning", "Не удалось количество заявок на вывод для меню ⚠️", `${error.response?.body?.message}`);
      });
    },

    getTraderSuccesCount: async () => {
      await Admin.getTraderSuccesCount().then((res) => {
        this.setState({ trader_success_count: res.body.count });
      })
      .catch((error) => {
        sendNotification("warning", "Не удалось получить количество заявок трейдеров ⚠️", `${error.response?.body?.message}`);
      });
    },

    updateSettings: async () => {
      let params = {};
      if (!isNull(this.state.debtList)) params.debtList = this.state.debtList.split(",");
      params.comments = this.state.comments;
      params.externalId = this.state.externalId;
      params.dateAndTime = this.state.dateAndTime;
      params.sumAndComsa = this.state.sumAndComsa;
      await Admin.updateSettings(params).catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        this.setState({
          redirectPath: "/",
          redirectMessage: "Что то пошло не так. ⚠️",
          redirect: true,
        });
      });
    },

    updateState: async (key, val) =>
      await this.setState((state) => {
        state[key] = val;
        return state;
    }),

    confirmTraderOrder: async (id, manager_id, status) => {
      await Admin.confirmTraderOrder({ id, manager_id, status}).then((res) => {
        if (status === 'ACCEPTED') {
          sendNotification("success", "Успешно", "Заявка подтверждена, и переведена в успех.");
        }
        if (status === 'DECLINED') {
          sendNotification("success", "Успешно", "Заявка отменена, и переведена в ошибку.");
        }
      })
      .catch((error) => {
        sendNotification("warning", "Не удалось количество заявок трейдеров ⚠️", `${error.response?.body?.message}`);
      });
    },

    clear: () => {
      this.setState({
        corrections: [],
        settings: {},
        autoPayment: null,
        debtList: null,
        users_short_list: [],
        pending_withdraws_count: null,
        trader_success_count: null,
      });
    },
    corrections: [],
    settings: {},
    rerenderSettings: false,
    debtList: null,
  };

  render() {
    return (
      <AdminContext.Provider value={this.state}>
        {this.props.children}
      </AdminContext.Provider>
    );
  }
}

export const AdminConsumer = AdminContext.Consumer;
