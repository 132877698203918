import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {Link } from "react-feather";

import { sendNotification } from "../../helpers/notification";
import AdminBar from "../../components/AdminBar";
import { OrderContext } from "../../context/orders-context";
import { UserContext } from "../../context/users-context";
import {AlignJustify} from "react-feather";

const SendCallback = () => {

  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const OrderCunsumer = useContext(OrderContext);
  const userCunsumer = React.useContext(UserContext);

  useEffect(() => {
    if (OrderCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
      setRedirectPath(OrderCunsumer.redirectPath);
      OrderCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OrderCunsumer.redirect]);

  const handler = async (event) => {
    await OrderCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    return OrderCunsumer.sendCallback()
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ", e.response.body.message);
        }
      });
  };

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect
          to={{
            pathname: redirectPath,
          }}
        />
      );
    }
  };

  return (
    <>
      {renderRedirect()}
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className=" d-flex justify-content-between align-items-center flex-wrap w-100">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Отправить коллбек
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row">
              <div className="col-12 col-xl-6">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group">
                        <label style={{ fontSize: 16, color: '#fff' }} htmlFor="callbackUrl">
                          <strong>Введите ссылку куда отправить коллбек:</strong>
                        </label>
                        <input
                          type="text"
                          onChange={handler}
                          className="form-control"
                          placeholder="https://url"
                          name="callbackUrl"
                          id="callbackUrl"
                          style={{height: '40px'}}
                        />
                        <p className="mt-1 text-muted">(ссылку надо взять с заявки)</p>
                      </div>
                      <div className="form-group">
                        <label style={{ fontSize: 16, color: '#fff' }} htmlFor="callbackOrderId">
                          <strong>ID заявки:</strong>
                        </label>
                        <input
                          type="text"
                          onChange={handler}
                          className="form-control"
                          placeholder="203"
                          name="callbackOrderId"
                          id="callbackOrderId"
                          style={{height: '40px'}}
                        />
                      </div>
                      <button
                        onClick={(e) => submit(e)}
                        className={`btn btn-line-primary btn-line-primary-hover btn-small mt-3`}
                      >
                        Отправить коллбек  <Link className="link-icon ms-1 mb-1" size={15} />
                      </button>
                    </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default SendCallback;