import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { sendNotification } from "../../helpers/notification";
import AdminBar from "../../components/AdminBar";
import TwoFa from "../../components/TwoFa";
import { UserContext } from "../../context/users-context";
import {AlignJustify} from "react-feather";

const AdminSettings = () => {

  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const userCunsumer = useContext(UserContext);

  React.useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (userCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", userCunsumer.redirectMessage);
      setRedirectPath(userCunsumer.redirectPath);
      userCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCunsumer.redirect]); 

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect
          to={{
            pathname: redirectPath,
          }}
        />
      );
    }
  };

  return (
    <>
      {renderRedirect()}
      {loading && <p>Loading</p>}
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Настройки
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <TwoFa />
            <section  className="choose-area-two banner-bg mt-1 media-d-none" style={{padding: '200px 0 10px 0', borderRadius: '8px'}}>
              <div  className="container custom-container-four">
                <div  className="row why-we">
                  <div  className="col-lg-6 col-sm-6 pl-4">
                    <div  className="section-title text-start">
                      <span  className="sub-title mt-1">Apex Group</span>
                    </div>
                  </div>
                  <div  className="col-lg-6 col-sm-6 pl-4">
                    <h4 style={{textTransform: 'none'}}>
                      Зарабатывайте % на выплатах со своей карты.
                    </h4>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSettings;
