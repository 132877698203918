import React, { useContext, useEffect } from "react";
import { OrderContext } from "../../context/orders-context";
import { Sliders } from "react-feather";

const SummSelected = (props) => {

  const [summ, setSumm] = React.useState(0);
  const OrderCunsumer = useContext(OrderContext);

  useEffect(() => {
      let total = 0;
      OrderCunsumer.orderArray.map((item) => {
        total = parseFloat(total) + parseFloat(item.amount)
        return false;
      })
      setSumm(total);
  }, [OrderCunsumer.orderArray]);
  
  return (
    <>
      <div className="card mb-4">
        <div className="card-body">
          <div className="row">
            <h5 className="mb-4 color-primary" style={{textTransform: 'none'}} >Сумма выделенных заявок
              <Sliders className="link-icon ms-2 mb-1" style={{width: '16px'}}/>          
            </h5>
            <h5 className="my-2">{summ} RUB</h5>
            <span style={{fontSize: '14px'}}>выделите нужные заявки, чтобы узнать их сумму</span>
          </div>
          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-line-primary btn-line-primary-hover btn-small mt-3"
                onClick={() => props.handleClearSelected(true) }
              >
                Очистить выделенные заявки
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummSelected;