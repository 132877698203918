import React, { useContext, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import Checkbox from '@material-ui/core/Checkbox';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-data-table-component-extensions/dist/index.css';

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import Filter from "../../components/forms/FilterAdmin";
import SummSelected from "../../components/divs/SummSelected";
import CheckModal from "../manager/components/CheckModal";
import { sendNotification } from "../../helpers/notification";

import { AdminContext } from "../../context/admin-context";
import { ManagerContext } from "../../context/manager-context";
import { OrderContext } from "../../context/orders-context";
import { UserContext } from "../../context/users-context";

import { FileText, AlignJustify} from "react-feather";

const getStatusBadge = (status) => {
  switch (status) {
    case "COMPLETED":
      return "btn-line-success"
    case "PENDING":
      return "btn-line-primary"
    case "ERROR":
      return "btn-line-danger"
    case "MARKED_AS_COMPLETED":
      return "btn-line-info"
    default:
      return "btn-line-warning"
  }
}

const getStatus = (status) => {
  switch (status) {
    case "COMPLETED":
      return "Выполненная"
    case "PENDING":
      return "В работе"
    case "ERROR":
      return "Ошибочная"
    case "MARKED_AS_COMPLETED":
      return "Выполнил трейдер"
    default:
      return "Новая"
  }
}

const Home = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [showSearch, setShowSearch] = React.useState(true);
  const [clearOrderArray, setClearOrderArray] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [showCheck, setShowCheck] = React.useState(false);
  const [copyStatus, setCopyStatus] = React.useState({});
  const [filter, setFilter] = React.useState({
    bank: '',
    status: '',
    min_sum: null,
    max_sum: null,
    limit: null,
    manager_id: null,
  });
  const adminCunsumer = useContext(AdminContext);
  const managerCunsumer = useContext(ManagerContext);
  const OrderCunsumer = useContext(OrderContext);
  const userCunsumer = useContext(UserContext);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    adminCunsumer.getSettings();
    OrderCunsumer.getOrders();
    adminCunsumer.getUsersShortList();
    return () => OrderCunsumer.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (OrderCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
      setRedirectPath(OrderCunsumer.redirectPath);
      OrderCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OrderCunsumer.redirect]); 

  const togglePopUp = useCallback(
    (trigger) => {
      setShowCheck(false);
      managerCunsumer.updateState('checkFile', '');
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCheck]);

  const updateData = () => {
    OrderCunsumer.clear();
    setTimeout(() => {
        OrderCunsumer.getOrders(filter);
    }, 200);
  };

  const downloadInvoice = (id) => {
    managerCunsumer.getCheck(id);
    setShowCheck(true);
  }

  let columns = [
    { name: "id",
      sortable: true,
      width: "80px",
      selector: (row) => row.id,
      cellExport: row => row.id,
    },
    { name: "manager",
      sortable: true,
      width: "90px",
      selector: (row) => row.managerId,
      cellExport: row => row.managerId,
    },
    { name: "Статус",
      sortable: true,
      width: "180px",
      selector: (row) => row.status,
      cellExport: row => row.status,
      cell: (row) => {
        return (
          <button
            type="button"
            style={{ fontSize: 11 }}
            className={`btn-line ${getStatusBadge(row.status)}`}
          >
            {getStatus(row.status)}
          </button>
        );
      },
    },
    { name: "Карта",
      sortable: true,
      width: "250px",
      selector: (row) => row.card,
      cellExport: row => row.card,
      cell: (row, index) => {
        return (
          <div>
            {copyStatus.copied && index === copyStatus.index && 
              <div className="popover" >
                <div className="arrow"></div>
                <p>Скопировано</p>
              </div>  
            }
            <span className={`${!isEmpty(adminCunsumer.settings.debtList) && adminCunsumer.settings.debtList.includes(row.card) ? 'text-danger font-weight-bold mr-2' : 'mr-2'}`}>{row.card}</span>
            <CopyToClipboard text={row.card}
              onCopy={() => {
                setCopyStatus({ copied: true, index: index })
                setTimeout(() => {
                  setCopyStatus({ copied: false })
                }, 700);
              }}
            >
              <i className="fa-solid fa-copy copy-card"></i>
            </CopyToClipboard>
          </div>
        )
      },
    },
    { name: "Cумма в RUB",
      sortable: true,
      width: "120px",
      selector: (row) => row.amount,
      cellExport: (row) => row.amount,
      cell: (row) => {
        return `${row.amount}`;
      },
    },
    { name: 'Cумма в USDT',
      selector: (row) => row.total,
      sortable: true,
      width: "130px",
      cellExport: (row) => {
        return `${row.total === row.amount ? 'Cумма в RUB' : row.total}`;
      },
      cell: (row) => {
        return `${row.total === row.amount ? 'Cумма в RUB' : `${row.total} USDT`}`;
      },
    },
    { name: "Курс",
      selector: (row) => row.course,
      sortable: true,
      width: '100px',
      cell: (row) => {
        return `${row.course}`;
      },
    },
    { name: "Банк",
      sortable: true,
      width: "120px",
      selector: (row) => row.bank,
      cellExport: (row) => row.bank,
      cell: (row) => {
        return `${row.bank}`;
      },
    },
    { ignoreRowClick: false,
      button: true,
      name: "Чек",
      width: "145px",
      cellExport: (row) => !isEmpty(row?.metadata?.check) ? "Чек есть" : "Нету",
      cell: (row) => {
        return (
          !isEmpty(row?.metadata?.check) &&
          <button
            className="btn-line btn-line-primary btn-line-primary-hover"
            onClick={() => downloadInvoice(row.id)}
          >
            Смотреть чек
          </button>
        );
      },
    },
    { name: "Коммент:",
      width: "250px",
      selector: (row) => row.comment,
      cellExport: (row) => row.comment,
    },
    { name: "Доп. Коммент:",
      width: "200px",
      selector: (row) => row.comment2,
      cellExport: (row) => row.comment2,
    },
    { name: "Дата создания",
      sortable: true,
      width: "195px",
      selector: (row) => row.createdAt,
      cellExport: (row) => row.createdAt,
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    { name: "Выполненная в",
      sortable: true,
      width: "195px",
      selector: (row) => row.successedAt,
      cellExport: (row) => row.successedAt,
      cell: (row) => {
        return (
          <span>{row.successedAt ? moment(row.successedAt).format("D/MM/YYYY, H:mm:ss") : '-------'}</span>
        );
      },
    },
    { name: "External ID",
      selector: (row) => row.externalId,
      sortable: true,
      width: "200px",
      cellExport: (row) => row.externalId,
      cell: (row) => {
        return `${row.externalId}`;
      },
    },
    { name: "Callback url",
      selector: (row) => row.callbackUrl,
      sortable: true,
      width: "400px",
      cellExport: (row) => row.callbackUrl,
      cell: (row) => {
        return `${row.callbackUrl}`;
      },
    },
  ];

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}}/>
      );
    }
  };

  const hendleSelected = async (state) => {
    await setShowSearch(false);
    await OrderCunsumer.updateState('orderArray', state.selectedRows);
    if (isEmpty(OrderCunsumer.orderArray)) {
      await setShowSearch(true);
    }
  }

  const handleClearSelected = async (bool) => {
    await setClearOrderArray(true);
    await OrderCunsumer.updateState('orderArray', []);
    await setShowSearch(true);
    await setClearOrderArray(false);
  }

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const tableData = {
    columns: columns,
    data: OrderCunsumer.orders,
    print: false,
    filter: showSearch,
    filterPlaceholder: 'Универсальный поиск...',
    export: true,
    exportHeaders: true
  };

  return (
    <>
      {renderRedirect()}
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className=" d-flex justify-content-between align-items-center flex-wrap w-100">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title media-fs-17" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Последние заявки 1000шт.
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row">
              <div className="col-12 col-xl-12">
                <div className="row">
                  <div className="col-md-7 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <Filter updateFilter={setFilter} manager_list={adminCunsumer.users_short_list} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 grid-margin">
                    <SummSelected handleClearSelected={() => handleClearSelected() } />
                  </div>
                </div>
                <CheckModal
                  togglePopUp={togglePopUp}
                  show={showCheck}
                />
                <div className="row ">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h6 className="card-title text-primary">
                            Последние заявки
                            <FileText className="link-icon ms-1 mb-1" style={{width: '16px'}}/>
                        </h6>
                        {isEmpty(OrderCunsumer.orders) ? (
                          <Loader />
                        ) : (
                          <div className="table-responsive">
                            <div id="dataTableExample_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                              <DataTableExtensions {...tableData} >
                                <DataTable
                                  title={false}
                                  columns={columns}
                                  highlightOnHover={true}
                                  striped={true}
                                  pointerOnHover={true}
                                  className={"table dataTable"}
                                  pagination={true}
                                  noHeader={true}
                                  theme="solarized"
                                  paginationPerPage={50}
                                  selectableRows={true}
                                  selectableRowsComponent={Checkbox}
                                  selectableRowsComponentProps={{ color: 'primary' }}
                                  selectableRowsVisibleOnly={true}
                                  clearSelectedRows={clearOrderArray}
                                  selectableRowsHighlight={true}
                                  onSelectedRowsChange={hendleSelected}
                                  paginationRowsPerPageOptions={[
                                    50,
                                    100,
                                    200,
                                    300,
                                    400,
                                  ]}
                                />
                              </DataTableExtensions>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;