import React, { useContext } from "react";
import { ManagerContext } from "../../../context/manager-context";

const RangeForm = (props) => { 
	const managerCunsumer = useContext(ManagerContext);

  // const getRange = (value) => {
  //   switch (value) {
  //     case '1':
  //       return '1000-500000';
  //     case '2':
  //       return '1000-10000';
  //     case '3':
  //       return '10000-500000';
  //   }
  // }

  // const hendleLimit = async (event) => {
  //   if (event.target.name === 'range') {
  //     await managerCunsumer.updateRange(getRange(event.target.value));
  //   }
  // };

  const assignOrders = async (e) => {
    if (e) {
      e.preventDefault();
    }
    await managerCunsumer.assignOrders().then(() => {
      managerCunsumer.getActiveOrders();
      managerCunsumer.getDebtList();
    })
  }

  return (
    <>
      <form onSubmit={(e) => assignOrders(e)}>
				<div className="form-group">
          <div className="row">
            <div className="col-7">
              <label htmlFor="select" style={{color: '#bfc3ce', marginTop: '10px'}}>Выберите диапазон сум заявок, с которым хотите работать</label>
            </div>
            {/* <div className="col-5">
              <select className="form-control" id="select" name="range" onChange={hendleLimit}>
                <option defaultValue>Выбрать</option>
                <option value="1">Все заявки (без лимита)</option>
                <option value="2">Заявки до 10 000 руб.</option>
                <option value="3">Заявки от 10 000 руб.</option>
              </select>
            </div> */}
          </div>
				</div>
				<button className="btn btn-line-info btn-line-info-hover btn-small mt-2" type="submit">Получить заявки</button>
			</form>
    </>
  );
};

export default RangeForm;