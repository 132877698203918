import React, { useContext } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Filter as FilterIcon, X} from "react-feather";

import { ManagerContext } from "../../../context/manager-context";
import { sendNotification } from "../../../helpers/notification";
import Loader from "../../../components/Loader";

const FilterOrders = (props) => {
  const [loading, setLoading] = React.useState(false);
  const ManagerCunsumer = useContext(ManagerContext);

  const setFilter = async (name, value) => {
    await ManagerCunsumer.updateState('filter', { ...ManagerCunsumer.filter, [name]: value })
  };

  const submit = async () => {
    setLoading(true);
    return ManagerCunsumer.getArchiveOrders()
      .then(() => sendNotification("success", "Успех", "Заявки отфильтрованные."))
      .then(() => setLoading(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка", e.response.body.message);
        }
      });
  };

  const clean = async () => {
    setLoading(true);
    await ManagerCunsumer.updateState('filter', {});
    return ManagerCunsumer.getArchiveOrders()
      .then(() => sendNotification("success", "Успех", "Фильтры сброшены."))
      .then(() => setLoading(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка", e.response.body.message);
        }
      });
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()} >
        <div className="row">
          <div className="col-md-6">          
            <div className="form-group">
              <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="status">
                <strong>Статус:</strong>
              </label>
              <select
                onChange={(evet) => setFilter('status', evet.target.value)}
                className="form-control"
                value={ManagerCunsumer.filter && ManagerCunsumer.filter.status ? ManagerCunsumer.filter.status : ''}
              >
                <option value="" defaultValue>
                  Все статусы
                </option>
                <option value={"ERROR"}>Ошибочная</option>
                <option value={"COMPLETED"}>Выполненная</option>
              </select>
            </div>
          </div>
          <div className="col-md-6"> 
            <div className="form-group">
              <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="limit">
                <strong>Выберите дату:</strong>
              </label>
              <DatePicker 
                selected={ManagerCunsumer?.filter?.date ? ManagerCunsumer.filter.date : ''}
                placeholder="Выберите дату"
                wrapperClassName="form-control"
                className="form-control"
                dateFormat='dd-MM-yyyy' 
                onChange={(date) => setFilter('date', date)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12"> 
            {loading === true ? ( 
                <Loader/>
            ) : (
              <>
                <button
                  onClick={(e) => submit(e)}
                  className={`btn btn-line-primary btn-line-primary-hover btn-small mt-4 me-3`}
                >
                  Фильтровать <FilterIcon className="link-icon ml-1" style={{position: 'relative', top: '-1px'}} size={16} />
                </button>
                <button
                  onClick={(e) => clean(e)}
                  className={`btn btn-line-danger btn-line-danger-hover btn-small mt-4`}
                >
                  Сбросить <X className="link-icon ml-1" style={{position: 'relative', top: '-1px'}} size={16} />
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default FilterOrders;