import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import moment from "moment";

import Loader from "../../../components/Loader";

moment.locale('ru'); 

const ManagerWithdrawsTable = (props) => {
  
  const [loading, setLoading] = React.useState(true);
  const [showSearch] = React.useState(true);

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      width: "90px",
    },
    {
      name: "Статус",
      selector: (row) => row.status,
      sortable: true,
      width: "125px",
      cell: (row ) => {
        return (
          <button
            type="button"
            style={{ fontSize: 11}}
            className={`btn-line ${
              row.status === "COMPLETED"
                ? "btn-line-success"
                : row.status === "PENDING"
                ? "btn-line-primary"
                : row.status === "ERROR"
                ? "btn-line-danger"
                : "btn-line-warning"
            }`}
          >
            {row.status === "COMPLETED"
              ? "Выплачено"
              : row.status === "PENDING"
              ? "Обработка"
              : row.status === "ERROR"
              ? "Ошибка"
              : "Новая"}
          </button>
        );
      },
    },
    {
      name: "Сумма",
      selector: (row) => row.amount,
      sortable: true,
      width: "150px",
      cell: (row) => {
        return `${row.amount} ${props.currency}`;
      },
    },
    
    {
      name: "Кошелек TRC20",
      sortable: true,
      width: "350px",
      cell: (row) => {
        return <span>{row.card}</span>;
      },
    },
    {
      name: "Коммент:",
      selector: (row) => row.comment,
      width: "240px",
      cell: (row) => {
        return `${row.comment}`;
      },
    },
    {
      name: "Дата и время",
      sortable: true,
      width: "185px",
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
  ];

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const tableData = {
    columns: columns,
    data: props.orders,
    export: false,
    print: false,
    filter: showSearch,
    filterPlaceholder: 'Универсальный поиск...'
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="card-body">
            <h6 className="card-title" style={{color: '#eef4ff'}}>
              Ваши заявки на вывод
            </h6>
            <hr />
            { isEmpty(props.orders) ? (
              <h5 className="text-center text-muted pt-3 pb-2" style={{textTransform: 'none'}}>История пока пуста </h5>
            ) : (
              <div className="table-responsive">
                <div
                  id="dataTableExample_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <DataTableExtensions {...tableData} >
                    <DataTable
                      title={false}
                      columns={columns}
                      highlightOnHover={true}
                      striped={true}
                      pointerOnHover={true}
                      className={"table dataTable"}
                      pagination={true}
                      noHeader={true}
                      theme="solarized"
                      paginationPerPage={50}
                      paginationRowsPerPageOptions={[
                        50,
                        100,
                        200,
                        300,
                        400,
                      ]}
                    />
                  </DataTableExtensions>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ManagerWithdrawsTable;