import React from 'react';
import ManagerBar from "../../components/ManagerBar";

const White = () => {

    return(
      <>
        <div className="main-wrapper">
          <ManagerBar />
          <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
            <div className="page-content">
              <div className="row">
                <div className="col-12" style={{minHeight: '150px'}}>
                  <div className="banner-content text-center mt-5">
                    <h4 className="title mb-1 color-primary" style={{textTransform: 'uppercase', fontSize: '20px',}}>Добро пожаловать!</h4>
                    <h2 style={{textTransform: 'none', fontSize: '20px'}} className="title text-muted">Для начала работы, выберите пункт из меню</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <section className="banner-area choose-bg mt-2"  style={{padding: '30px 0 10px 0', borderRadius: '8px', minHeight: '600px'}}>
                      <div className="container">
                          <div className="row justify-content-center">
                              <div className="col-lg-10 text-center">
                                <img width={350} src={require('../../images/logo2.png')} className="mb-5" alt="" />
                              </div>
                          </div>
                      </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
};

export default White;