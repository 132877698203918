import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Filter as FilterIcon, X} from "react-feather";

// import { UserContext } from "../../../context/users-context";
import { sendNotification } from "../../../helpers/notification";
// import Loader from "../../../components/Loader";
import { OrderContext } from "../../../context/orders-context";

const UserFilterOrders = (props) => {
  const [loading, setLoading] = React.useState(false);
  const OrderCunsumer = useContext(OrderContext);

  const setFilter = async (name, value) => {
    await OrderCunsumer.updateState('filter', { ...OrderCunsumer.filter, [name]: value })
  };

  const submit = async () => {
    setLoading(true);
    return OrderCunsumer.getUserOrders()
    .then(() => sendNotification("success", "Успех", "Заявки отфильтрованные."))
    .then(() => setLoading(false))
    .catch((e) => {
      console.log("e", JSON.stringify(e));
      if (e.response && e.response.body.message) {
        sendNotification("danger", "Ошибка", e.response.body.message);
      }
    });
  };

  const clean = async () => {
    setLoading(true);
    await OrderCunsumer.updateState('filter', {});
    return OrderCunsumer.getUserOrders()
    .then(() => sendNotification("success", "Успех", "Фильтры сброшены."))
    .then(() => setLoading(false))
    .catch((e) => {
      if (e.response && e.response.body.message) {
        sendNotification("danger", "Ошибка", e.response.body.message);
      }
    });
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="row">
          <div className="col-md-6">          
            <div className="form-group">
              <label style={{ fontSize: 14, color: '#a4b4c3' }} htmlFor="status">
                <strong>Статус:</strong>
              </label>
              <select
                onChange={(evet) => setFilter('status', evet.target.value)}
                className="form-control"
                value={OrderCunsumer.filter && OrderCunsumer.filter.status ? OrderCunsumer.filter.status : ''}
              >
                <option value="" defaultValue>
                  Все статусы
                </option>
                <option value={"ERROR"}>Ошибочная</option>
                <option value={"COMPLETED"}>Выполненная</option>
                <option value={"PENDING"}>В роботе</option>
                <option value={"CREATED"}>Новая</option>
              </select>
            </div>
          </div>
          <div className="col-md-6"> 
            <div className="form-group">
              <label style={{ fontSize: 14, color: '#a4b4c3'}} htmlFor="limit">
                <strong>Выберите дату создания:</strong>
              </label>
              <DatePicker 
                selected={OrderCunsumer.filter && OrderCunsumer.filter.date && OrderCunsumer.filter.date}
                placeholder="Выберите дату"
                wrapperClassName="form-control"
                className="form-control"
                dateFormat='dd-MM-yyyy' 
                onChange={(date) => setFilter('date', date)}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12"> 
            {loading === true ? ( 
                <button className="btn btn-line-info btn-small mt-4 ms-1">
                  <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                  Загрузка...
                </button>    
            ) : (
              <>
                <button
                  onClick={(e) => submit(e)}
                  className={`btn btn-line-primary btn-line-primary-hover btn-small mt-4 ms-1 me-3`}
                >
                  Фильтровать <FilterIcon className="link-icon ml-1" style={{position: 'relative', top: '-1px'}} size={16} />
                </button>
                <button
                  onClick={(e) => clean(e)}
                  className={`btn btn-line-danger btn-line-danger-hover btn-small mt-4`}
                >
                  Сбросить <X className="link-icon ml-1" style={{position: 'relative', top: '-1px'}} size={16} />
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default UserFilterOrders;