import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import DataTable, {createTheme} from "react-data-table-component";
import moment from "moment";
import Checkbox from '@material-ui/core/Checkbox';

import { sendNotification } from "../../helpers/notification";
import UserBar from "../../components/UserBar";
import Loader from "../../components/Loader";
import UserFilterOrders from "./components/UserFilterOrders";
import UserSumm from "./components/UserSumm";

import { OrderContext } from "../../context/orders-context";
import { AuthContext } from "../../context/auth-context";
import { UserContext } from "../../context/users-context";

import { FileText, AlignJustify} from "react-feather"; 


const UserHome = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [clearOrderArray, setClearOrderArray] = React.useState(false);
  // const [showSearch, setShowSearch] = React.useState(true);
  const authCunsumer = useContext(AuthContext);
  const userCunsumer = useContext(UserContext);
  const OrderCunsumer = useContext(OrderContext);

  useEffect(() => {
    OrderCunsumer.getUserOrders(authCunsumer.user.id);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (OrderCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
      setRedirectPath(OrderCunsumer.redirectPath);
      OrderCunsumer.clear();
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OrderCunsumer.redirect]);

  let columns = [
    { name: "id",
      selector: (row) => row.id,
      sortable: true,
      width: "70px",
    },
    { name: "Статус",
      selector: (row) => row.status,
      sortable: true,
      width: "140px",
      cell: (row, index, column, id) => {
        return (
          <button
            type="button"
            style={{ fontSize: 11 }}
            className={`btn-line ${
              row.status === "COMPLETED"
            ? "btn-line-success"
            : row.status === "PENDING"
            ? "btn-line-primary"
            : row.status === "ERROR"
            ? "btn-line-danger"
            : "btn-line-warning"
            }`}
          >
            {row.status === "COMPLETED"
              ? "Выполненная"
              : row.status === "PENDING"
              ? "В работе"
              : row.status === "ERROR"
              ? "Ошибочная"
              : "Новая"}
          </button>
        );
      },
    },
    { name: "Карта",
      selector: (row) => row.card,
      sortable: true,
      width: "180px",
      cell: (row) => {
        return `${row.card}`;
      },
    },
    { name: "Cумма в RUB",
      selector: (row) => row.amount,
      sortable: true,
      width: "120px",
      cell: (row) => {
        return `${row.amount}`;
      },
    },
    { name: 'Cумма в USDT',
      selector: (row) => row.total,
      sortable: true,
      width: "130px",
      cell: (row) => {
        return `${row.total === row.amount ? 'Cумма в RUB' : `${row.total} USDT`}`;
      },
    },
    { name: "Курс",
      selector: (row) => row.course,
      sortable: true,
      width: '100px',
      cell: (row) => {
        return `${row.course}`;
      },
    },
    { name: "Банк",
      selector: (row) => row.bank,
      sortable: true,
      width: "180px",
      cell: (row) => {
        return `${row.bank}`;
      },
    },
    { name: "Дата создания",
      selector: (row) => row.createdAt,
      sortable: true,
      width: "230px",
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("DD/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    { name: "Выполненная в",
      selector: (row) => row.successedAt,
      sortable: true,
      width: "185px",
      cell: (row) => {
        return (
          <span>{row.successedAt ? moment(row.successedAt).format("D/MM/YYYY, H:mm:ss") : '-------'}</span>
        );
      },
    },
    { name: "External ID",
      selector: (row) => row.externalId,
      sortable: true,
      width: "200px",
      cellExport: row => (''),
      cell: (row) => {
        return `${row.externalId}`;
      },
    },
    { name: "Callback url",
      selector: (row) => row.callbackUrl,
      sortable: true,
      width: "350px",
      cellExport: row => (''),
      cell: (row) => {
        return `${row.callbackUrl}`;
      },
    },
  ];

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}}/>
      );
    }
  };

  const hendleSelected = async (state) => {
    // await setShowSearch(false);
    await OrderCunsumer.updateState('orderArray', state.selectedRows);
    // if (isEmpty(OrderCunsumer.orderArray)) {
    //   await setShowSearch(true);
    // }
  }

  const handleClearSelected = async (bool) => {
    await setClearOrderArray(true);
    await OrderCunsumer.updateState('orderArray', []);
    // await setShowSearch(true);
    await setClearOrderArray(false);
  }

  return (
    <>
      {renderRedirect()}
      {loading && 
        <Loader/>
      }
      <div className="main-wrapper">
        <UserBar />
        <div className="page-wrapper"  style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Информация о мерчанте
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row  d-flex justify-content-start">
              <div className="col-12 col-xl-12">
                <div className="row">
                  <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <UserFilterOrders />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <UserSumm handleClearSelected={() => handleClearSelected() } />
                  </div>
                </div>
              </div>
              {isEmpty(OrderCunsumer.orders) ? (
                <div className="col-md-12 grid-margin">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title color-primary">Заявки
                        <FileText className="link-icon ms-1 mb-1" style={{width: '16px'}}/>
                      </h6>
                      <div className="text-center">
                        <h5 className="text-muted py-3" style={{textTransform: 'none'}}>Заявок пока нету...</h5>
                      </div>
                    </div>
                  </div>
                </div> 
              ) : (
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title">Заявки</h6>
                        <div className="table-responsive">
                          <div
                            id="dataTableExample_wrapper"
                            className="dataTables_wrapper dt-bootstrap4 no-footer"
                          >
                            <DataTable
                              title={false}
                              columns={columns}
                              data={OrderCunsumer.orders}
                              highlightOnHover={true}
                              striped={true}
                              pointerOnHover={true}
                              className={"table dataTable"}
                              pagination={true}
                              noHeader={true}
                              theme="solarized"
                              paginationPerPage={50}
                              paginationRowsPerPageOptions={[
                                50,
                                100,
                                200,
                                300,
                                400,
                              ]}
                              selectableRowsComponent={Checkbox}
                              selectableRowsComponentProps={{ color: 'primary' }}
                              selectableRows={true}
                              selectableRowsVisibleOnly={true}
                              clearSelectedRows={clearOrderArray}
                              selectableRowsHighlight={true}
                              onSelectedRowsChange={hendleSelected}
                            />
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHome;