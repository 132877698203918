import React, {useEffect} from "react";

import Loader from "../../components/Loader";
import UserBar from "../../components/UserBar";
import { UserContext } from "../../context/users-context";
import { OrderContext } from "../../context/orders-context";
import { CreditCard, AlignJustify, FilePlus, Plus } from "react-feather";
import { isEmpty } from "lodash";

const UserHome = (props) => {

  const [loading, setLoading] = React.useState(true);
  const orderCunsumer = React.useContext(OrderContext);
  const userCunsumer = React.useContext(UserContext);

  React.useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hendler = async (event) => {
    await orderCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    orderCunsumer.createOrder().then(() => {
      userCunsumer.userMe();
      setLoading(false);
    });
  };

  useEffect(() => {
    // setLoading(true);
    // userCunsumer.getCurrency();
    return function cleanup() {
      userCunsumer.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!isEmpty(userCunsumer.currency)) {
      setLoading(false)
    }
  }, [userCunsumer.currency]);

  return (
    <>
      {loading && <p>Loading</p>}
      <div className="main-wrapper">
        <UserBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        Создать заявку
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row  d-flex justify-content-start">
              <div className="col-12 col-md-6 col-xl-6">
                <div className="card">
                  <div className="card-body">
                    {loading ? (
                      <Loader />
                    ) : (
                      <form
                        className="forms-sample"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <h5 className="mb-4 color-primary" style={{textTransform: 'none'}} >Создайте новою заявку
                          <FilePlus className="link-icon ms-2" style={{width: '18px'}}/>
                        </h5>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1" style={{fontSize: 14, color: '#fff'}}>
                            <b>Сумма в RUB</b>
                          </label>
                          <input
                            type="text"
                            name="amount"
                            onChange={hendler}
                            placeholder="2000"
                            required
                            className="form-control"
                            id="exampleInputEmail1"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1" style={{fontSize: 14, color: '#fff'}}>
                            <b>Карта</b>
                          </label>
                          <input
                            type="text"
                            name="card"
                            onChange={hendler}
                            required
                            placeholder="XXXX-XXXX-XXXX-XXXX"
                            className="form-control"
                            id="exampleInputPassword1"
                            autoComplete="current-password"
                          />
                        </div>
                        <div className="form-group">
                          <label style={{fontSize: 14, color: '#fff'}} htmlFor="bank">
                            <strong>Банк:</strong>
                          </label>
                          <select
                            onChange={hendler}
                            className="form-control"
                            name="bank"
                            id="bank"
                          >
                            <option value="" defaultValue>
                              Выберите банк
                            </option>
                            <option value={"sberbank"}>sberbank</option>
                            <option value={"tinkoff"}>tinkoff</option>
                            <option value={"visa/master"}>visa/master</option>
                          </select>
                        </div>
                        <div className="mt-3">
                          <button
                            className={`btn btn-line-primary btn-line-primary-hover btn-small mt-3`}
                            onClick={(e) => submit(e)}
                          >
                            Создать заявку{" "}
                            <Plus className="link-icon ms-1 mb-1" style={{width: '16px'}}/>
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3 grid-margin media-mt-3">
                <div className="card">
                  <div className="card-body" >
                    <h6 className="card-title-dark">
                      Ваш баланс <CreditCard className="link-icon ms-2" style={{width: '18px'}}/>
                    </h6>
                    <hr className="mt-2 mb-4"/>
                    <h4  style={{fontSize: '20px'}} >
                      {userCunsumer.user.currency === 'RUB' ? userCunsumer.user.balance : userCunsumer.user.balanceUsdt} {userCunsumer.user.currency}
                    </h4>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 grid-margin">
                  <div className="card" > 
                    <div className="card-body">
                      <h6 className="card-title-dark">
                        Внутренний курс<DollarSign className="link-icon ms-2"  style={{width: '18px'}}/>
                      </h6>
                      <hr className="mt-2 mb-4"/>
                      <h4 style={{fontSize: '20px'}}> USDT/RUB  - 
                        {' '}{userCunsumer.currency ? userCunsumer.currency : '91,97'}
                      </h4>
                    </div>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHome;