import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";
import DataTable, {createTheme} from "react-data-table-component";

import AdminBar from "../../components/AdminBar";
import Loader from "../../components/Loader";
import { AdminContext } from "../../context/admin-context";
import { UserContext } from "../../context/users-context";
import {AlignJustify} from "react-feather";

const AdminCorrections = () => {

  const [loading, setLoading] = React.useState(true);
  const AdminCunsumer = useContext(AdminContext);
  const userCunsumer = React.useContext(UserContext);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    AdminCunsumer.getCorrections();
    return () => AdminCunsumer.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    {
      name: " Сумма коректировки",
      selector: (row) => row.amount,
      sortable: true,
      width: "180px",
      cell: (row) => {
        return <span style={{color: '#ff3366'}}>{row.amount} {row.metadata.currency}</span>;
      },
    },
    {
      name: "Баланс до корректировки",
      selector: (row) => row.oldBallance,
      sortable: true,
      width: "200px",
      cell: (row) => {
        return `${row.oldBallance} ${row.metadata.currency}`
      },
    },

    {
      name: "Баланс после корректировки",
      selector: (row) => row.newBallance,
      sortable: true,
      width: "220px",
      cell: (row) => {
        return `${row.newBallance} ${row.metadata.currency}`
      },
    },
    {
      name: "Комментарий",
      selector: (row) => row.comment,
      sortable: true,
    },
    {
      name: "ID пользователя",
      selector: (row) => row.userId,
      sortable: true,
      width: "150px",
      cell: (row) => {
        return <span style={{color: '#007bff'}}>{row.userId}</span>;
      },
    },
    {
      name: "Дата и время",
      sortable: true,
      width: "230px",
      cell: (row) => {
        return <span>{row.createdAt}</span>;
      },
    },
  ];

  createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  return (
    <>
      {loading && <Loader/>}
      <div className="main-wrapper">
        <AdminBar />
        <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
          <nav  className="navbar" style={{height: '60px'}}>
            <div  className="navbar-content">
              <form  className="search-form">
                <div  className="input-group">
                  <div className=" d-flex justify-content-between align-items-center flex-wrap w-100">
                    <div>
                      <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                        <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                        История корректировок
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </nav>
          <div className="page-content">
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                {isEmpty(AdminCunsumer.corrections) ? (
                  <div className="card">
                    <div className="card-body">
                      <p className="card-title text-center text-muted my-5">История корректировок пуста</p>
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <div
                          id="dataTableExample_wrapper"
                          className="dataTables_wrapper dt-bootstrap4 no-footer"
                        >
                          <DataTable
                            title={false}
                            columns={columns}
                            data={AdminCunsumer.corrections}
                            highlightOnHover={true}
                            striped={true}
                            pointerOnHover={true}
                            className={"table dataTable"}
                            pagination={true}
                            noHeader={true}
                            theme="solarized"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCorrections;