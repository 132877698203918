import React from 'react';
import AdminBar from "../../components/AdminBar";
import { AlignJustify } from "react-feather";
import { UserContext } from "../../context/users-context";

const White = () => {

  const userCunsumer = React.useContext(UserContext);

    return(
      <>
        <div className="main-wrapper">
          <AdminBar />
          <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
            <nav  className="navbar navbar-mobil" style={{height: '60px'}}>
              <div  className="navbar-content">
                <form  className="search-form">
                  <div  className="input-group">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <div>
                        <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                          <AlignJustify className="link-icon mb-1 me-3 icon-menu-mobil-media" style={{width: '18px'}} onClick = { () => {userCunsumer.setSidebarOpen(true)}}/>
                          <img alt='img' src={require("../../images/logo2.png")} width={85} className='ms-3' 
                            onClick = {() => {
                            if (window.innerWidth <= 768) {
                              userCunsumer.setSidebarOpen(false)
                            }
                          }}/>
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </nav>
            <div className="page-content">
              <div className="row">
                <div className="col-12" style={{minHeight: '150px'}}>
                  <div className="banner-content text-center mt-5">
                    <h4 className="title mb-1 color-primary" style={{textTransform: 'uppercase', fontSize: '20px',}}>Добро пожаловать!</h4>
                    <h2 style={{textTransform: 'none', fontSize: '20px'}} className="title text-muted">Для начала работы, выберите пункт из меню</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <section className="banner-area choose-bg mt-2"  style={{padding: '30px 0 10px 0', borderRadius: '8px', minHeight: '600px'}}>
                      <div className="container">
                          <div className="row justify-content-center">
                              <div className="col-lg-10 text-center">
                                <img width={350} src={require('../../images/logo2.png')} className="mb-5" alt="" />
                              </div>
                          </div>
                      </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
};

export default White;