import React from "react";
import { System, User } from "../helpers/api";
import { isEmpty } from "lodash";
import  secureLocalStorage  from  "react-secure-storage";

import { sendNotification } from "../helpers/notification";
import { evaluate } from "mathjs";
export const UserContext = React.createContext({});

export class UserProvider extends React.Component {
  state = {

    userMe: () => {
      return User.current()
      .then((res) => {
        this.setState({ user: res.body.user });
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        console.log(error.message);
        if (error.message === 'Forbidden') {
          this.setState({
            redirectPath: `/auth/login`,
            redirectMessage: "Время сесии истекло ⚠️",
            redirect: true,
          });
        } else {
          this.setState({
            redirectPath: `/${!isEmpty(secureLocalStorage.getItem('role') ? secureLocalStorage.getItem('role') : '/manager/home' )}/home`,
            redirectMessage: "Что то пошло не так. ⚠️",
            redirect: true,
          });
        }
      });
    },

    TwoFa: () => {
      let params = {};
      params.twoFa = this.state.twoFa;
      params.code = this.state.code;
      return User.TwoFa(params)
      .then((res) => {
        this.setState({ user: {} });
        return res;
      })
      .then((res) => {
        if (res.body.qrcode) {
          return this.setState({ qrcode: res.body.qrcode, fatoken: res.body.token });
        }
        sendNotification("success", "Удачно", `${res.body.message}`);
      })
      .catch((error) => {
        if (error.response?.body?.message?.includes("2fa")) {
          return sendNotification("warning", "Ошибка", "Bad 2fa code");
        }
        console.log("error", JSON.stringify(error, null, 4));
        this.setState({
          redirectPath: "/user-home",
          redirectMessage: "Что то пошло не так. ⚠️",
          redirect: true,
        });
      });
    },

    getUsers: async (params) => {
      await User.getUsers(params)
      .then((res) => {
        this.setState({ users: res.body.users });
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        this.setState({
          redirectPath: "/auth/login",
          redirectMessage: "Недостаточно прав ⚠️",
          redirect: true,
        });
      });
    },

    getStatistic: async () => {
      await User.getStatistic().then((res) => {
        this.setState({ stats: res.body });
      })
      .catch((error) => {
        sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить статистику. Обратитесь в службу поддержки.`);
      });
    },

    setUserToEdit: async (id) => {
      this.setState({ userToEdit: id });
    },
    setSidebarOpen: async (bool) => {
      this.setState({ sidebarOpen: bool });
    },
    updateBallance: async () => {
      let params = {};
      if (!isEmpty(this.state.balanceAmount))
        params.balanceAmount = this.state.balanceAmount;
      if (!isEmpty(this.state.comment)) params.comment = this.state.comment;
      return User.updateBallance(params, this.state.userToEdit);
    },

    updateState: async (key, val) =>
      await this.setState((state) => {
        state[key] = val;
        return state;
      }),

    getCurrency: async () => {
      await System.getCurrency().then((res) => {
        this.setState({ currency: evaluate(`${res.body.price} - (${res.body.price} * 0.01)`)});
      })
      .catch((error) => {
        console.log(error);
        sendNotification("warning", "Ошибка ⚠️", `Не удалось загрузить курс. Обратитесь в службу поддержки.`);
      });
    },

    settings: {},
    userToEdit: "",
    users: [],
    user: {},
    redirectPath: "",
    redirectMessage: "",
    redirect: false,
    balanceAmount: null,
    comment: "",
    stats: "",
    currency:'',
    sidebarOpen: true,
    clear: () => {
      this.setState({
        users: [],
        user: {},
        userToEdit: "",
        redirect: false,
        redirectPath: "",
        redirectMessage: "",
        balanceAmount: "",
        qrcode: "",
        fatoken: '',
        stats: "",
        currency:''
      });
    },
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
