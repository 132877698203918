import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import Cookies from "js-cookie";
const FormData = require('form-data');

const superagent = superagentPromise(_superagent, global.Promise);
const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;

const handleErrors = (err) => {
  if (err && err.response && err.response.status === 401) {
    console.log(err);
  }
  return err;
};

const tokenPlugin = (req) => {
  const jwt = Cookies.get("jwt");
  if (jwt) {
    req.set("Authorization", `Bearer ${jwt}`);
  }
};

const requests = {
  get: async (url, query) =>
    await superagent
      .get(`${API_URL}${url}`)
      .query(query)
      .use(tokenPlugin)
      .end(handleErrors),
  getBuffer: async (url, query) =>
    await superagent
      .get(`${API_URL}${url}`)
      .query(query)
      .use(tokenPlugin)
      .responseType('arraybuffer')
      .end(handleErrors),
  put: (url, body) =>
    superagent.put(`${API_URL}${url}`, body).use(tokenPlugin).end(handleErrors),
  post: (url, body) =>
    superagent
      .post(`${API_URL}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors),
  patch: (url, body, root = API_URL) =>
    superagent.patch(`${root}${url}`, body).use(tokenPlugin).end(handleErrors),
  delete: (url) =>
    superagent.del(`${API_URL}${url}`).use(tokenPlugin).end(handleErrors),
  multipart: (url, body) => {
    let data = new FormData();
    data.append('id', body.id);
    data.append('status', body.status);
    if (body.comment) data.append('comment', body.comment);
    if (body.comment2) data.append('comment2', body.comment2);
    if (body.skipcheck) data.append('skipcheck', body.skipcheck);
    if (body.check) data.append('check', body.check[0]);
    return superagent
      .post(`${API_URL}${url}`, data)
      .use(tokenPlugin)
      .end(handleErrors);
  }
};

export const Auth = {
  login: (email, password, code) =>
    requests.post("/auth/login", { email, password, code }),
};

export const Order = {
  getOrders: async (params) => await requests.get("/orders", params),
  createOrder: async (params) => await requests.post("/order/create-manual", params),
  getUserOrders: async (params) => await requests.get(`/user/orders/`, params),
  getSumm: async (comment, id) => await requests.get(`/orders/sum/${id}/${comment}`),
  sendCallback: async (url, id) => await requests.post(`/order/sendcallback/${id}`, { url }),
  getExport: async (id) => await requests.get(`/orders/export/${id}`),
  getExportByDate: async (params) => await requests.post(`/orders/export/date`, params),
  update: async (params, id) => await requests.put(`/order/update/${id}`, params),
  updateOrders: async (params) => await requests.put(`/orders/update`, params),
  changeStatus: async (params, id) => await requests.put(`/order/change/${id}`, params),
};

export const User = {
  current: () => requests.get("/user"),
  TwoFa: (params) => requests.post("/user/twoFa", params),
  getUsers: async (params) => await requests.get("/users", params),
  updateBallance: async (params, id) => await requests.post(`/user/update/${id}`, params),
  getStatistic: async () => requests.get('/orders/stats'),
};

export const Manager = {
  getProfit: async () => await requests.get("/manager/profit"),
  assignOrders: async (params) => requests.post('/orders/assign', params),
  getActiveOrders: async () => requests.get('/manager/orders/active'),
  getArchiveOrders: async (params) => requests.get('/manager/orders/archive', params),
  getCheck: async (id) => requests.getBuffer(`/check/${id}`),
  updateOrder: async (params) => await requests.multipart(`/order/status/updated`, params),
  withdrawRequest: async (params) => await requests.post(`/manager/withdrawal`, params),
  getWithdraws: async () => await requests.get(`/manager/withdraws`),
  getUsersWithdraws: async () => await requests.get(`/manager/withdrawals`),
  updateWithdrawStatus: async (params) => await requests.patch(`/manager/withdrawal/maked`, params),
  timerPlus: async (id) => await requests.patch(`/order/${id}/timer/plus`),
  getStatistic: async () => requests.get('/orders/stats'),
  getDebtList: async () => requests.get('/settings/debt/list'),
  addCardToDebt: async (params) => requests.put('/settings/debt/list', params),
};

export const Trader = {
  getProfit: async () => await requests.get("/trader/profit"),
  assignOrders: async (params) => requests.post('/orders/trader/assign', params),
  getActiveOrders: async () => requests.get('/trader/orders/active'),
  getArchiveOrders: async (params) => requests.get('/trader/orders/archive', params),
  updateOrder: async (params) => await requests.multipart(`/order/trader/status/update`, params),
  getCheck: async (id) => requests.getBuffer(`/check/${id}`),
  getStatistic: async () => requests.get('/orders/stats'),
  getWithdraws: async () => await requests.get(`/trader/withdraws`),
  withdrawRequest: async (params) => await requests.post(`/trader/withdrawal`, params),
};

export const Admin = {
  getCorrections: async () => await requests.get("/users/balance_histories"),
  getSettings: async () => await requests.get("/settings"),
  updateSettings: async (params) => await requests.put(`/settings/update`, params),
  getUsersShortList: async () => await requests.get("/users/shortlist"),
  getPendingWithdrawsCount: async () => await requests.get("/users/withdraw_pendding_count"),
  getTraderSuccesCount: async () => await requests.get("/users/trader_success_count"),
  confirmTraderOrder: async (params) => await requests.post("/order/trader/status/confirm", params),
};

export const System = {
  getCurrency: async () => {
    let res =  await superagent.get(`https://api.binance.com/api/v3/ticker/price`).query({symbols: ["USDTRUB"]}).end(handleErrors); 
    return res;
  }
}
