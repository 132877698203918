import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import  secureLocalStorage  from  "react-secure-storage";
import { UserContext } from "../context/users-context";

import {
  List,
  Settings,
  LogOut,
  FilePlus,
  BarChart,
  ChevronsLeft
} from "react-feather";

const UserBar = (props) => {

  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [redirectMessage, setredirectMessage] = React.useState("");
  const userCunsumer = React.useContext(UserContext);

  useEffect(() => {
    userCunsumer.userMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const logout = () => {
    Cookies.remove("jwt");
    secureLocalStorage.clear();
    setRedirectPath("/auth/login");
    setredirectMessage("👉 Вы вышли из системы.");
    setRedirect(true);
  };

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect
          to={{
            pathname: redirectPath,
            notify: {
              type: "success",
              title: "Успех ✅",
              message: redirectMessage,
            },
          }}
        />
      );
    }
  };

  const renderActiveClass = (type) => {
    if ( window.location.pathname === type ) {
      return "active";
    }
  };

  return (
    <>
      {renderRedirect()}
      <div className={`sidebar-dark ${userCunsumer.sidebarOpen === true && 'sidebar-open'}`}>
        <nav className="sidebar">
          <div className="sidebar-header">
            <Link to={`/${secureLocalStorage.getItem('role')}/home`} className="sidebar-brand" style={{width:'260px'}}
            onClick = {() => {
              if (window.innerWidth <= 768) {
                userCunsumer.setSidebarOpen(false)
              }
            }}>
              <img src={require("../images/logo2.png")} className="w-50" alt="" />
            </Link>
            <ChevronsLeft className="link-icon icon-menu-mobil-media" style={{width: '30px'}} onClick = { () => {userCunsumer.setSidebarOpen(false)}}/>
          </div>
          <div className="sidebar-body">
            <ul className="nav">
              <li className={`nav-item ${renderActiveClass("/user/create-order")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/user/create-order" className="nav-link">
                  <FilePlus className="link-icon" />
                  <span className="link-title">Создать заявку</span>
                </Link>
              </li>
              <li className={`nav-item ${renderActiveClass("/user/home")}`}
               onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/user/home" className="nav-link">
                  <List className="link-icon" />
                  <span className="link-title">Заявки</span>
                </Link>
              </li>
              <li className={`nav-item ${renderActiveClass("/user/statistic")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/user/statistic" className="nav-link">
                  <BarChart className="link-icon" />
                  <span className="link-title">Статистика</span>
                </Link>
              </li>
              <li className={`nav-item ${renderActiveClass("/user/settings")}`}
              onClick = {() => {
                if (window.innerWidth <= 768) {
                  userCunsumer.setSidebarOpen(false)
                }
              }}>
                <Link to="/user/settings" className="nav-link">
                  <Settings className="link-icon" />
                  <span className="link-title">Настройки</span>
                </Link>
              </li>
              <li className="nav-item">
                <span
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                  onClick={logout}
                >
                  <LogOut className="link-icon" />
                  <span className="link-title">Выход</span>
                </span>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default UserBar;