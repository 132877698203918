import React from "react";
import { isEmpty } from "lodash";

import Loader from "./Loader";
import { sendNotification } from "../helpers/notification";
import { UserContext } from "../context/users-context";

const UserSettings = () => {
  const [show, setShow] = React.useState(false);
  const [showInputCode, setShowInputCode] = React.useState(false);
  const userCunsumer = React.useContext(UserContext);

  const handlerTwo = async (event) => {
    if (event.target.name === "twoFa" && event.target.value === "false") {
      await userCunsumer.updateState(event.target.name, event.target.value);
      return setShowInputCode(true);
    }
    await userCunsumer.updateState(event.target.name, event.target.value);
  };

  React.useEffect(() => {
    userCunsumer.userMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const updateData = () => {
    setTimeout(() => {
      userCunsumer.userMe();
    }, 200);
  };

  const submitTwoFa = async (e) => {
    e.preventDefault();
    return userCunsumer
      .TwoFa()
      .then(() => {
        updateData();
      })
      .then(() => {
        if (!userCunsumer.user.twoFaEnabled) {
          setShow(!show);
          return sendNotification("success", "Удачно", "2fa ключ был включен");
        }
      })
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-12">
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body d-flex align-items-center">
                  {isEmpty(userCunsumer.user) ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="table-responsive d-flex align-items-center">
                        <table>
                          <tbody>
                            <tr style={{ fontSize: 16 }}>
                              <td className="media-fs-10" style={{ paddingRight: "15px" }}>
                                {<b>2FA ключ:</b>}
                              </td>
                              <td>
                                <select
                                  onChange={handlerTwo}
                                  className="form-control"
                                  name="twoFa"
                                >
                                  <>
                                    {userCunsumer.user.twoFaEnabled ? (
                                      <>
                                        <option value={true}>Включено</option>
                                        <option value={false}>Отключено</option>
                                      </>
                                    ) : (
                                      <>
                                        <option value={false}>Отключено</option>
                                        <option value={true}>Включено</option>
                                      </>
                                    )}
                                  </>
                                </select>
                              </td>
                              {userCunsumer.user.twoFaEnabled &&
                              showInputCode ? (
                                <>
                                  <td
                                    style={{
                                      paddingRight: "15px",
                                      paddingLeft: "15px",
                                    }}
                                  >
                                    {<b>2FA Код</b>}
                                  </td>
                                  <td>
                                    <input
                                      style={{
                                        maxWidth: "90px",
                                      }}
                                      type="text"
                                      name="code"
                                      onChange={handlerTwo}
                                      placeholder="*******"
                                      required
                                      className="form-control"
                                    />
                                  </td>
                                </>
                              ) : (
                                <></>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <button
                        style={{fontSize: '12px'}}
                        onClick={(e) => submitTwoFa(e)}
                        className={`btn media-btn-sm btn-two p-3 d-flex align-items-center justify-content-center`}
                      >
                        Сохранить <i className="mdi mdi-check"></i>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${show === true ? "show" : "d-none"}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md mt-200 " role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-end align-items-center">
              <button
                type="button "
                onClick={() => setShow(false)}
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-header d-flex justify-content-center align-items-center">
              <img className="" src={`${userCunsumer.qrcode}`} alt="Red dot" />
            </div>
            <h6 className="text-center py-4">{userCunsumer.fatoken ? userCunsumer.fatoken : ''}</h6>
            <div className="modal-footer d-flex justify-content-center">
              <h6 className="modal-title clr-red" id="warning" style={{textTransform: 'none'}}>
                <strong>Отсканируйте и сохраните</strong> данный код. <br/> Код повторно больше не будет показан.
              </h6>
              <button
                  onClick={() => setShow(false)}
                  className={`btn btn-two p-3 m-3 d-flex align-items-center justify-content-center`}
                  style={{fontSize: '13px'}}
                >
                  Я отсканировал и сохранил код в надежное место <i className="fa-solid fa-check ml-2"></i>
                </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSettings;