import React, { useContext, useEffect } from "react";
import { TraderContext } from "../../../context/trader-context";
import  secureLocalStorage  from  "react-secure-storage";

const RangeForm = (props) => { 
	const traderCunsumer = useContext(TraderContext);

  const hendleBank = async (event) => {
    await secureLocalStorage.setItem(event.target.name, event.target.checked);
  };

  const assignOrders = async (e) => {
    if (e) {
      e.preventDefault();
    }
    await traderCunsumer.assignOrders().then(() => {
      traderCunsumer.getActiveOrders();
    })
  }

  return (
    <>
      <form onSubmit={(e) => assignOrders(e)}>
				<div className="form-group">
          <div className="row">
            <div className="col-12">
              <h6 className="mt-2 mb-4" style={{ textTransform: 'none' }}>Выберите банки, по которым будете обрабатывать заявки:</h6>
              <div className="d-flex">
                <div className="form-check form-check-flat form-check-primary mb-2">
                  <label className="form-check-label text-white fs-18">
                    <input 
                      type="checkbox" 
                      className="form-check-input"
                      onChange={hendleBank}
                      name="sberbank"
                      defaultChecked={secureLocalStorage.getItem("sberbank")}
                    />
                    sberbank
                    <i className="input-frame"></i>
                  </label>
                </div>
                
                <div className="form-check form-check-flat form-check-primary mb-2 ms-4">
                  <label className="form-check-label text-white fs-18">
                    <input 
                      type="checkbox" 
                      className="form-check-input"
                      onChange={hendleBank}
                      name="tinkoff"
                      defaultChecked={secureLocalStorage.getItem("tinkoff")}
                    />
                    tinkoff
                    <i className="input-frame"></i>
                  </label>
                </div>
                
                <div className="form-check form-check-flat form-check-primary mb-2 ms-4">
                  <label className="form-check-label text-white fs-18">
                    <input 
                      type="checkbox" 
                      className="form-check-input"
                      onChange={hendleBank}
                      name="visamaster"
                      defaultChecked={secureLocalStorage.getItem("visamaster")}
                    />
                    visa/master
                    <i className="input-frame"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
				</div>
				<button className="btn btn-line-info btn-line-info-hover btn-small mt-2" type="submit">Получить заявки</button>
			</form>
    </>
  );
};

export default RangeForm;