import React, { useContext, useState, useEffect, useCallback } from "react";
import { TraderContext } from "../../../context/trader-context";
import { sendNotification } from "../../../helpers/notification";
import { isEmpty } from "lodash";
import { useDropzone } from 'react-dropzone'
import Loader from "../../../components/Loader";

import { Download } from "react-feather";

const ChangeStatusModal = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState();
  const [paths, setPaths] = useState([]);
  const [checkError, setCheckError] = useState(false);

  const traderCunsumer = useContext(TraderContext);
  const { show } = props;

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    if (!isEmpty(traderCunsumer.activeOrder)) {
      let order = traderCunsumer.orders.filter((obj) => {
        return obj.id === traderCunsumer.activeOrder;
      });
      setOrder(order[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traderCunsumer.activeOrder]); 

  const updateField = async (event) => {
    await traderCunsumer.updateState(event.target.name, event.target.value);
  };

  const onDrop = useCallback(acceptedFiles => {
    setCheckError(false)
    traderCunsumer.updateState('check', acceptedFiles);
    setPaths(acceptedFiles.map(file => URL.createObjectURL(file)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPaths]) 
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: false})

  const getStatus = (status) => {
    switch (status) {
      case "COMPLETED":
        return "Выполненная"
      case "PENDING":
        return "В работе"
      case "ERROR":
        return "Ошибочная"
      default:
        return "Новая"
    }
  }

  const getConfirmStatus = (status) => {
    switch (status) {
      case "COMPLETED":
        return "Выполненную"
      case "ERROR":
        return "Ошибочную"
      case "MAKE_OPEN":
        return "Отказаться от заявки"
    }
  }

  const submit = async () => {
    if (traderCunsumer.status === "MAKE_OPEN" && isEmpty(traderCunsumer.comment)) {
      sendNotification("danger", "Укжите причину", "Укажите причину отказа в комментарии");
      return;
    }

    if (traderCunsumer.status === "COMPLETED" && isEmpty(traderCunsumer.check)) {
      setCheckError(true);
      sendNotification("danger", "Прикрепите чек", "Для статуса Выполненная - необходимо прикрепить чек");
      return;
    }

    if (window.confirm(`Вы уверены что изменить статус заявки ID: ${order.id}, на ${getConfirmStatus(traderCunsumer.status)} ?`)) {
      return traderCunsumer
      .updateOrder()
      .then(setPaths([]))
      .then(simulateClick.current.click())
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
    }
  };

  const handleClose = () => {
    setPaths([]);
    props.togglePopUp();
  }

  const simulateClick = React.useRef(null);

  return (
    <>
      {loading ? (
        <Loader/>
      ) : (
        <div
          className={`modal fade ${show === true ? "show" : "d-none"}`}
          tabIndex="-1"
          style={{overflow: 'scroll'}}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content  p-3">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Смена статуса заявки id:{order && order.id}
                </h5>
                <button
                  type="button"
                  onClick={handleClose}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span style={{color: 'white'}} aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-white">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group" style={{ fontSize: 16 }}>
                        <p className="mb-1">ID заявки: {order && order.id}</p>
                        <p className="mb-1">
                          <strong>
                            Текущий статус: { order && getStatus(order.status) }
                          </strong>
                        </p>
                        <p className="mb-1">Карта: {order && order.card}</p>
                        <p className="mb-1">Сумма: {order && order.amount}</p>
                        <p className="mb-1">Банк: {order && order.bank}</p>
                        <p className="mb-1">
                          Время создания: {order && order.createdAt}
                        </p>
                      </div>
                      <div className="form-group">
                        <label style={{ fontSize: 16 }} htmlFor="status">
                          <strong>Иземнить статус на:</strong>
                        </label>
                        <select
                          onChange={updateField}
                          value={traderCunsumer.status ? traderCunsumer.status : ''}
                          className="form-control"
                          name="status"
                          id="status"
                          required
                        >
                          <option defaultValue>
                            Выбирите статус
                          </option>
                          <option value={"COMPLETED"}>Выполненная ✅</option>
                          <option value={"ERROR"}>Ошибочная ⛔️</option>
                          <option value={"MAKE_OPEN"}>Освободить заявку 🔄</option>
                        </select>
                      </div>
                      { !isEmpty(traderCunsumer.status) && traderCunsumer.status === "COMPLETED" &&
                        <>
                          <div {...getRootProps()} className={`fileDropzone mt-2 mb-4 ${checkError ? 'error-border' : !isEmpty(traderCunsumer.check) ? 'success-border' : ''}`} >
                            <input {...getInputProps()} />
                            {isDragActive ? <p>Перетащите файл сюда ...</p> :
                              !isEmpty(paths) ? (
                                <div>
                                  {
                                    paths.map(path => 
                                      <img key={path} src={path} height={'190px'} width={'auto'} alt="check" />
                                    )
                                  }
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setPaths([]);
                                      traderCunsumer.updateState('check', []);
                                    }}
                                    className="close text-white"
                                    style={{ right: '10px', position: 'relative' }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                              ) : (
                                <>
                                  <div className={`text-center mb-3 ${checkError ? 'text-danger' : 'text-muted'}`}>
                                    <Download size={48} />
                                  </div>
                                  <h6 className={`mb-2 ${checkError ? 'text-danger' : ''}`}>Прикрепите чек</h6>
                                  <p>Перетащите чек сюда, или кликните для выбора файла</p>
                                </>
                              )
                            }
                          </div>
                        </>
                      }
                      <div className="form-group">
                        <label style={{ fontSize: 16 }} htmlFor="comment">
                          <strong>Комментарий:</strong>
                        </label>
                        <input
                          type="text"
                          onChange={updateField}
                          value={traderCunsumer.comment ? traderCunsumer.comment : ''}
                          className="form-control"
                          name="comment"
                          id="comment"
                        />
                      </div>
                      <div className="form-group">
                        <label style={{ fontSize: 16 }} htmlFor="comment">
                          <strong>Доп. комментарий:</strong>
                        </label>
                        <input
                          type="text"
                          onChange={updateField}
                          value={traderCunsumer.comment2 ? traderCunsumer.comment2 : ''}
                          className="form-control"
                          name="comment2"
                          id="comment2"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  ref={simulateClick}
                  onClick={handleClose}
                  type="button"
                  className="btn btn-gray btn-small"
                  data-dismiss="modal"
                >
                  Закрыть
                </button>
                <button
                  onClick={(e) => submit(e)}
                  className={`btn btn-green btn-small`}
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangeStatusModal;