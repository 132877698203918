import React, { useContext, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";

import Loader from "../../components/Loader";
import TraderBar from "../../components/TraderBar";
import ChangeStatusModal from "./components/changeStatus";
import RangeForm from "./components/RangeForm";
import TraderOrderCard from "./components/TraderOrderCard";
import { sendNotification } from "../../helpers/notification";

import { TraderContext } from "../../context/trader-context";
import { UserContext } from "../../context/users-context";

import {
  FileText,
  Layers,
  BarChart2,
  CreditCard,
  XSquare
} from "react-feather";

moment.locale('ru'); 

const Orders = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [changeStatusModal, setChangeStatusModal] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [exportList, setExportList] = React.useState(null);
  
  const userCunsumer = useContext(UserContext);
  const traderCunsumer = useContext(TraderContext);

  useEffect(() => {
    setLoading(true);
    traderCunsumer.getActiveOrders();
    setLoading(false);
    return function cleanup() {
      traderCunsumer.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    if (userCunsumer.redirectMessage) {
      sendNotification("warning", "Ошибка ⚠️", userCunsumer.redirectMessage);
      userCunsumer.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCunsumer.redirectMessage]);

  const cutOverdueOrder = (id) => {
    traderCunsumer.overdueOrder(id);
  }

  const handler = (id) => {
    traderCunsumer.updateState('activeOrder', id);
    setChangeStatusModal(true);
  };

  const togglePopUp = useCallback(
    (trigger) => {
      setChangeStatusModal(false);
      traderCunsumer.updateState('status', '');
      traderCunsumer.updateState('check', null);
      traderCunsumer.updateState('comment', '');
      traderCunsumer.updateState('comment2', '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeStatusModal]
  );

  const updateData = () => {
    traderCunsumer.clear();
    setTimeout(() => {
      traderCunsumer.getActiveOrders();
    }, 200);
  };

  const getExportList = async () => {
    setExportList(null);
    setExportLoading(true);
    let list = await traderCunsumer.getExportList();
    if (!isEmpty(list)) {
      setExportList(list)
    } else {
      setExportList('Не удалось получить список. попробуйте обновить страницу, и повторить.')
    }
    setExportLoading(false);
  }

  const updateColor = async (id) => {
    traderCunsumer.updateColor(id);
  }

  return (
    <>
      {userCunsumer.redirect && userCunsumer.redirectPath && <Redirect to={{ pathname: userCunsumer.redirectPath, }} />}
      {loading && isEmpty(userCunsumer.user) ? (
        <Loader />
      ) : (
        <div className="main-wrapper">
          <TraderBar />
          <div className="page-wrapper" style={{backgroundColor: '#070d19'}}>
            <ChangeStatusModal
              updateData={() => updateData()}
              togglePopUp={togglePopUp}
              show={changeStatusModal}
            />
            <nav  className="navbar" style={{height: '60px'}}>
              <div  className="navbar-content">
                <form  className="search-form">
                  <div  className="input-group">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <div>
                        <span className="mt-4 mb-md-0 sub-title" style={{fontWeight: '500', fontSize: '18px', color: '#fff', letterSpacing: '0.02em'}}>
                          Заявки на выплату
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </nav>
            <div className="page-head">
              <div className="page-content" style={{backgroundColor: '#070d19', paddingBottom: '15px'}}>
                <div className="row d-flex justify-content-start">
                  <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                      <div style={{padding: '20px'}}  className="card-body pb-1" >
                        <h6 className="card-title-dark">
                          Инструкция <FileText className="link-icon ms-2" style={{width: '18px'}}/>
                        </h6>
                        <hr className="mt-3 mb-4"/>
                        <h6 className="card-title-main" ><strong>1.</strong> Переведите сумму из заявки на указанную карту из заявки.</h6>
                        <h6 className="card-title-main" ><strong>2.</strong> Измените статус заявки, если заявка успешная, <strong>прикрепите чек.</strong></h6>
                        <h6 className="card-title-main" ><strong>3.</strong> Проверьте начисление % на Ваш баланс.</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body" style={{padding: '20px'}} >
                        <h6 className="card-title-dark">
                          ЛИМИТ ОЧЕРЕДИ ЗАЯВОК <Layers className="link-icon ms-2" style={{width: '18px'}}/>
                        </h6>
                        <hr className="mt-3 mb-4"/>
                        <p className="mb-2 card-title">Лимит:
                          <span className="ms-2" style={{fontWeight: '600', color: '#007bff'}}>{userCunsumer.user.limit} шт.</span>
                        </p>
                        <p className="mb-2 card-title">Получено:
                          <span className="ms-2" style={{fontWeight: '600', color: '#007bff'}}>{traderCunsumer.orders.length} шт.</span>
                        </p>
                        <p className="mb-2 card-title">Можно еще получить:
                          <span className="ms-2" style={{fontWeight: '600', color: '#007bff'}}>{userCunsumer.user.limit - traderCunsumer.orders.length} шт.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body" style={{padding: '20px'}} >
                        <h6 className="card-title-dark">
                          ДЕЙСТВИЯ <BarChart2 className="link-icon ms-2" style={{width: '18px'}}/>
                        </h6>
                        <hr className="mt-3 mb-4"/>
                        <div className='text-center'>
                            <button 
                              className="btn btn-line-info btn-line-info-hover btn-small-xs mb-2 mt-2"
                              style={{minWidth: '190px', fontSize: '11.5px'}}
                              onClick={() => traderCunsumer.assignOrders() }
                            >
                              Получить заявки
                              <CreditCard className="link-icon ms-2 mb-1" style={{width: '14px'}}/>
                            </button>
                            <button onClick={() => traderCunsumer.clearColors()} className="btn btn-line-dark btn-line-info-hover btn-small mt-2">Очистить цвета</button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div>
                    <p style={{textTransform: 'uppercase'}} className="text-muted">Заявки для обработки</p>
                  </div>
                </div>
                  { !isEmpty(traderCunsumer.orders) ? (
                    <>
                      {!isEmpty(exportList) &&
                        <div className="row justify-content-center mb-4">
                          <div className="col-md-8 ">
                            <div className="card text-white bg-dark px-5 py-4" style={{ whiteSpace: 'pre-wrap' }}>
                              <XSquare onClick={() => setExportList(null)} className="closeexportlist" />
                              {exportList}
                            </div>
                          </div>
                        </div>
                      }
                      <div className="row justify-content-center mb-3">
                        <div className="col-md-2 text-center">
                          { exportLoading ? 
                            (
                              <button className="btn btn-info btn-small mt-2 disabled" style={{ opacity: '1'}}>Загрузка ...</button>
                            ) : (
                              <button onClick={getExportList} className="btn btn-line-info btn-line-info-hover btn-small mt-2">Експорт всех 👉</button>
                            )
                          } 
                        </div>
                      </div>
                    <div className="row row-cols-md-3 row-cols-lg-3 row-cols-xxl-3 row-cols-xxxl-4">
                      
                      {traderCunsumer.orders.map((item,index) => {
                        return (
                          <TraderOrderCard 
                            key={index}
                            item={item} 
                            index={index}
                            handler={handler}
                            cutOverdueOrder={cutOverdueOrder}
                            colors={traderCunsumer.colors}
                            updColor={updateColor}
                          />
                        )
                      })}
                    </div>
                    </>
                  ) : (
                    <div className="row d-flex justify-content-start">
                      <div className="col-md-12 stretch-card">
                        <div className="card">
                          <div className="card-body" style={{padding: '30px 20px'}} >
                              <h5 style={{textTransform: 'none'}}>Заявок нету, выберите банки и получите заявки</h5>
                              <hr />
                              <RangeForm />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Orders;